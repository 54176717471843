import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Modal, Popconfirm, Tooltip } from "antd";
import { useForm } from "antd/es/form/Form";
import { useContext, useEffect, useState } from "react";
import { HomeContext } from "../../containers/Home";
import { GetAntIcon } from "../../utils/ant_icons";
import Emitter from "../../utils/emitter";
import { getCrudaClass } from "../../utils/lookup_list";
import CustomForm from "../CustomForm";
import FormButtonSave from "../FormButtonSave";

const PhaseReorder = (props: any) => {
	const [open, setOpen] = useState(false);
	const [formRef] = useForm();
	const context: any = useContext(HomeContext);

	const [formReset, setFormReset] = useState<boolean>(true);
	const [updated, setUpdated] = useState<boolean>(false);
	const [initialForm, setInitialForm]: any = useState<any>(null);
	const [cancelPopover, setCancelPopover] = useState<any>(false);

	const resetForm = (values: any = null, reset: any = true) => {
		if (formRef) {
			setFormReset(true);
			reset && formRef.resetFields();
			formRef.setFieldsValue(values ? { ...values } : null);
			setInitialForm(values);
		}
	};

	// To reset the form when the modal is open or closed
	useEffect(() => {
		const _data = { phase_data: props.data };
		if (open) {
			console.log("i-debug Reorder phase", props);
			resetForm(_data);
		} else {
			resetForm();
		}
	}, [open, props.data]);

	const handleFormSave = (values: any) => {
		getCrudaClass("phase-reorder").then((cruda: any) => {
			const form = cruda.getFormList("phase-reorder");
			const formList: any = [];
			form.forEach((item: any) => {
				if (item.name) {
					formList.push(item.name);
				}
			});

			const cleanedInitialForm = initialForm.map((item: any) => {
				return {
					_id: item._id,
					sequence_id: item.sequence_id,
					phase_name: item.phase_name,
				};
			});

			cruda
				.updateLookupItem(
					"phase-reorder",
					props?.option,
					context?.currentTenantKey,
					{ phase_data: values.phase_data },
					{ phase_data: cleanedInitialForm },
					props.otherData,
					props.user,
					formList
				)
				.then(() => {
					Emitter.emit("alert", {
						type: "success",
						message: `Phase order has been successfully updated.`,
						description: "",
						top: true,
						closeable: false,
						timeout: 3000,
					});
					resetForm(values.phase_data, true);
					setUpdated(!updated);
					Emitter.emit("forceUpdate", null);
					// props.setUpdated(!updated);
				})
				.catch((error: any) => {
					Emitter.emit("alert", {
						type: "error",
						message: "Changes are not saved. Please try again.",
						description: "",
						top: true,
						closeable: false,
						timeout: 3000,
					});
				});
		});
	};

	const handleFormCancel = () => {
		resetForm();
		setOpen(false);
	};

	return (
		<>
			<Button onClick={() => setOpen(true)}>
				<div
					style={{
						display: "flex",
						alignContent: "center",
						justifyContent: "center",
						gap: "5px",
					}}
				>
					{GetAntIcon("reorder")}
					<span>Reorder phases</span>
				</div>
			</Button>

			<Modal
				closable={false}
				width={"30%"}
				title={
					<>
						<span>Phase order configuration</span>{" "}
						<Tooltip
							title={"Drag and drop phases to adjust their order."}
							placement="right"
						>
							<span style={{ color: "rgba(255,255,255,0.45)" }}>
								{GetAntIcon("question3")}
							</span>
						</Tooltip>
					</>
				}
				open={open}
				centered
				destroyOnClose
				footer={() => (
					<>
						<Popconfirm
							icon={<ExclamationCircleOutlined style={{ color: "grey" }} />}
							title={
								<div style={{ maxWidth: "300px", padding: "10px" }}>
									<>
										You have unsaved changes. Discard them or continue editing?
									</>
								</div>
							}
							open={cancelPopover}
							placement="topRight"
							cancelButtonProps={{ ghost: true }}
							cancelText="Discard changes"
							onCancel={() => {
								setCancelPopover(false);
								setTimeout(() => {
									handleFormCancel();
								}, 300);
							}}
							okText="Continue editing"
							okType="default"
							onConfirm={() => {
								setCancelPopover(false);
							}}
						>
							<Button
								className="ant-btn-secondary"
								onClick={() => {
									if (formReset) {
										handleFormCancel();
									} else {
										setCancelPopover(true);
									}
								}}
							>
								Cancel
							</Button>
						</Popconfirm>
						<FormButtonSave
							form={formRef}
							handleFormSave={handleFormSave}
							formReset={formReset}
						></FormButtonSave>
					</>
				)}
			>
				<CustomForm
					setFormReset={setFormReset}
					formRef={formRef}
					tabKey={"phase-reorder"}
					dataOptions={{ phase_data: props.data }}
					formOption={props.option}
				/>
			</Modal>
		</>
	);
};

export default PhaseReorder;
