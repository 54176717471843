import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space, Typography } from "antd";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import CheckboxDropdown from "../components/CheckboxDropdown";
import WellConfigurationModal from "../components/Modal/WellConfigurationModal";
import Map from "../components/Overview/Map";
import WellGallery from "../components/Wells/WellGallery";
import useHandleContextMenu from "../hooks/useHandleContextMenu";
import usePermission from "../hooks/usePermission";
import { getWells } from "../services/api-server/wells";
import { GetAntIcon } from "../utils/ant_icons";
import Emitter from "../utils/emitter";
import { setLookupData } from "../utils/lookupFinder";
import { HomeContext } from "./Home";
import NoAccess from "./NoAccess";
import useContainerDimensions from "../hooks/useContainerDimensions";

const { Text } = Typography;

function PWellOverview(props: any) {
	const context: any = useContext(HomeContext);
	const location = useLocation();
	const navigate = useNavigate();

	// Permissions
	const hasPermissionViewWell = usePermission("View.Well");

	const [activePage, setActivePage] = useState<any>("map");
	const [activeWells, setActiveWells] = useState<any>([]);

	const [openWellModal, setOpenWellModal] = useState<boolean>(false);

	//Map & Gallery states
	const [wellList, setWellList] = useState<any>(null);
	const [selectedWell, setSelectedWell] = useState<any>(null);
	const [selectedWellInfo, setSelectedWellInfo] = useState<any>(null);

	// Filtering states
	const [filteredWellList, setFilteredWellList] = useState<any>(null);
	const [filterMode, setFilterMode] = useState<boolean>(false);
	const [searchValue, setSearchValue] = useState<string>("");
	const [descMode, setDescMode] = useState<boolean>(false);
	const [selectedFilters, setSelectedFilters] = useState<any>({
		location: [],
		well_operator: [],
		well_purpose: [],
		well_status: [],
	});

	const [updated, setUpdated] = useState<boolean>(false);
	const [containerHeight, setContainerHeight] = useState<any>(null);

	//Audit Logs states
	const [auditOpen, setAuditOpen] = useState<boolean>(false);

	const { handleContextMenu } = useHandleContextMenu();
	const [tableRef, tableDimensions] = useContainerDimensions();

	const sortData = (data: any) => {
		data.sort((a: any, b: any) => {
			if (a.label.toLowerCase() < b.label.toLowerCase()) {
				return -1;
			}
			if (a.label.toLowerCase() > b.label.toLowerCase()) {
				return 1;
			}
			return 0;
		});
		return data;
	};

	useEffect(() => {
		const handleWellModal = () => {
			setOpenWellModal(true);
		};

		const reloadWells = () => {
			setUpdated(!updated);
		};

		Emitter.on("wellModalOpen", handleWellModal);
		Emitter.on("reloadWells", reloadWells);

		return () => {
			Emitter.off("wellModalOpen", handleWellModal);
			Emitter.off("reloadWells", reloadWells);
		};
	}, []);

	//Fetch Physical Well List
	useEffect(() => {
		Emitter.emit("loading", true);
		if ((context.currentTenantKey, context.allData)) {
			if (localStorage.getItem("selectedFilters")) {
				let selectedFiltersStore: any = localStorage.getItem("selectedFilters");
				let selectedFiltersStoreJson = JSON.parse(selectedFiltersStore);
				setSelectedFilters(selectedFiltersStoreJson);
				setFilterMode(true);
			}

			getWells(context.currentTenantKey, {})
				.then((_data: any) => {
					if (!_data) return;
					// Data mapping in case need to add more fields
					const wellData = _data.map((item: any) => {
						return {
							...item,
						};
					});
					setWellList(wellData);
				})
				.catch((error: any) => {
					console.log(error);
				});
		}
		Emitter.emit("loading", false);
	}, [updated, context.currentTenantKey, context.allData]);

	const selectWell = (wellKey: any, well: any) => {
		if (selectedWell != wellKey) {
			setSelectedWell(wellKey);
			setSelectedWellInfo(well);
			const selectedElement = document.getElementById(`project-${wellKey}`);

			setTimeout(() => {
				if (selectedElement) {
					selectedElement.scrollIntoView({ behavior: "smooth" });
				}
			}, 1);
		} else {
			setSelectedWell(null);
			setSelectedWellInfo(null);
		}
	};

	const hasSelectedFilters = () => {
		return Object.values(selectedFilters).some(
			(values: any) => values.length > 0
		);
	};

	// useMemo: Ensures the filterRender component is only re-created when filterMode or selectedFilters changes, improving performance by avoiding unnecessary re-renders.
	const filterRender = useMemo(() => {
		// This function is called when a filter's selection changes.
		const handleMenuClick = (field: any, selectedValues: any[]) => {
			setSelectedFilters({ ...selectedFilters, [field]: selectedValues });
			localStorage.setItem(
				"selectedFilters",
				JSON.stringify({ ...selectedFilters, [field]: selectedValues })
			);
		};

		// Resets all filters to their initial (empty) state.
		const handleClearAllFilters = () => {
			setSelectedFilters({
				location: [],
				well_operator: [],
				well_purpose: [],
				well_status: [],
			});
			// Reset search value
			setSearchValue("");

			// Turns off the filter mode (if it was active).
			setFilterMode(false);

			setDescMode(false); // Reset to the default sort (ascending or set it to your desired default)

			// Clears the saved filters from localStorage.
			localStorage.removeItem("selectedFilters");

			// Briefly toggles filterMode off and back on to trigger any dependent UI updates.
			setTimeout(() => {
				setFilterMode(true);
			}, 5);
		};

		return (
			<>
				{/* Location */}
				<CheckboxDropdown
					options={[
						{
							label: "Onshore",
							value: "onshore",
						},
						{
							label: "Offshore",
							value: "offshore",
						},
					]}
					onChange={(selectedValues) =>
						handleMenuClick("location", selectedValues)
					}
					onClear={""}
					defaultValue={selectedFilters.location}
					Key="location"
					label="Location"
				/>
				{/* Well Operator */}
				<CheckboxDropdown
					options={context?.allData?.company?.map((wo: any) => ({
						label: wo.name,
						value: wo.uuid,
					}))}
					onChange={(selectedValues) =>
						handleMenuClick("well_operator", selectedValues)
					}
					onClear={""}
					defaultValue={selectedFilters["well_operator"]}
					Key="well_operator"
					label="Well operator"
				/>
				{/* Well Purpose */}
				<CheckboxDropdown
					options={context?.allData?.well_purpose?.map((wp: any) => ({
						label: wp.name,
						value: wp.uuid,
					}))}
					onChange={(selectedValues) =>
						handleMenuClick("well_purpose", selectedValues)
					}
					onClear={""}
					defaultValue={selectedFilters["well_purpose"]}
					Key="well_purpose"
					label="Well purpose"
				/>
				{/* Well Status */}
				<CheckboxDropdown
					options={context?.allData?.well_status?.map((ws: any) => ({
						label: ws.name,
						value: ws.uuid,
					}))}
					onChange={(selectedValues) =>
						handleMenuClick("well_status", selectedValues)
					}
					onClear={""}
					defaultValue={selectedFilters["well_status"]}
					Key="well_status"
					label="Well status"
				/>
				{/* Reset All */}
				<div className="test">
					<Button type="link" onClick={handleClearAllFilters}>
						Reset All
					</Button>
				</div>
			</>
		);
	}, [filterMode, selectedFilters]);

	const listWells = () => {
		const setWellContent = (currentWellData: any, infoKey: any) => {
			let content = "-";
			if (
				currentWellData[infoKey] ||
				infoKey == "latitude" ||
				infoKey == "longitude"
			) {
				content = setLookupData(context.allData, currentWellData, infoKey);
				if (infoKey === "location") {
					content =
						currentWellData[infoKey].charAt(0).toUpperCase() +
							currentWellData[infoKey].slice(1) || "-";
				}
			} else if (infoKey === "actual_start_date") {
				if (currentWellData?.actual_start_date) {
					var date = new Date(currentWellData.actual_start_date);
					// change the date format to "1 Mar 2024"
					content = date.toLocaleDateString("en-GB", {
						year: "numeric",
						month: "short",
						day: "numeric",
					});
				} else {
					content = "-";
				}
			}
			return content;
		};

		const renderPurpose = (
			wellProject: any,
			purpose: string,
			purposeType: string
		) => {
			//console.log(wellProject?.[purposeType]);
			switch (purpose) {
				case "In progress":
					return (
						<div className="project-status-item status-counter-in-progress">
							<span>
								{purpose} ({purposeType})
							</span>
						</div>
					);

				case "New":
					return (
						<div className="project-status-item status-counter-new">
							<span>New ({purposeType})</span>
						</div>
					);

				case "Resolved":
					return (
						<div className="project-status-item status-counter-completed">
							<span>Resolved ({purposeType})</span>
						</div>
					);
				default:
					if (purpose) {
						return (
							<div className="project-status-item status-counter-in-progress">
								<span>
									{setLookupData(context.allData, wellProject, purpose)}{" "}
									{wellProject?.[purposeType] && (
										<>
											(
											{setLookupData(context.allData, wellProject, purposeType)}
											)
										</>
									)}
								</span>
							</div>
						);
					} else {
						return <></>;
					}
			}
		};
		const renderStatus = (
			wellProject: any,
			status: string,
			statusType: string
		) => {
			switch (status) {
				case "In progress":
					return (
						<div className="project-status-item status-counter-in-progress">
							<span>In Progress</span>
						</div>
					);

				case "New":
					return (
						<div className="project-status-item status-counter-new">
							<span>New</span>
						</div>
					);

				case "Resolved":
					return (
						<div className="project-status-item status-counter-completed">
							<span>Resolved</span>
						</div>
					);
				default:
					if (status) {
						return (
							<div className="project-status-item status-counter-in-progress">
								<span>
									{setLookupData(context.allData, wellProject, status)}{" "}
									{wellProject?.[statusType] && (
										<>
											({setLookupData(context.allData, wellProject, statusType)}
											)
										</>
									)}
								</span>
							</div>
						);
					} else {
						return <></>;
					}
			}
		};

		const renderDetail = (wellProject: any) => {
			const showDetailInfo = () => {
				const infoKeyList: any = [
					{ label: "License", key: "license" },
					{ label: "Well operator", key: "well_operator_id" },
					{ label: "Block", key: "block" },
					{ label: "Field", key: "field" },
					{ label: "Water depth", key: "water_depth" },
					{ label: "Location", key: "location" },
					{ label: "Latitude", key: "latitude" },
					{ label: "Longitude", key: "longitude" },
				];

				return (
					<>
						{infoKeyList.map((infoKey: any) => {
							return (
								<Space direction="vertical" size={0}>
									<span style={{ opacity: 0.45, fontSize: "12px" }}>
										{infoKey.label}
									</span>
									<div
										style={{
											display: "flex",
											justifyContent: "flex-start",
											alignItems: "center",
											fontSize: "14px",
											minWidth: "100%",
											width: "0",
										}}
									>
										<Text
											title=""
											style={
												infoKey.key == "country"
													? {
															whiteSpace: "nowrap",
															overflow: "hidden",
															textOverflow: "ellipsis",
													  }
													: {}
											}
											ellipsis={{
												tooltip: (
													<span>
														{setWellContent(wellProject, infoKey.key)}
													</span>
												),
											}}
										>
											<span
												style={
													infoKey.key == "country"
														? {
																whiteSpace: "nowrap",
																overflow: "hidden",
																textOverflow: "ellipsis",
														  }
														: {}
												}
											>
												{setWellContent(wellProject, infoKey.key)}
											</span>
										</Text>
									</div>
								</Space>
							);
						})}
					</>
				);
			};

			return (
				<div className="detailed-card">
					<Space direction="vertical" style={{ width: "100%" }} size={15}>
						<Space
							direction="vertical"
							size={0}
							style={{ display: "flex", flexDirection: "column" }}
						>
							<span className="detailed-card-label">Well name</span>
							<div style={{ fontSize: "20px", lineHeight: "25px" }}>
								<span
									className={"project-title-clickable detailed-card-title"}
									onClick={() => {
										navigate(`${wellProject._id}`, {
											replace: false,
											state: {
												...wellProject,
												state: { ...wellProject },
											},
										});
									}}
									onContextMenu={(event: any) => {
										const location = `${window.location.href}/${wellProject._id}`;
										handleContextMenu(event, { link: location });
									}}
								>
									{wellProject.well_name}
								</span>
							</div>
						</Space>
						<div className="project-status">
							{renderPurpose(wellProject, "well_purpose", "well_purpose_type")}
							{renderStatus(wellProject, "well_status", "well_status_type")}
						</div>
						<div className="project-grid-container">{showDetailInfo()}</div>
					</Space>
				</div>
			);
		};

		const renderCompact = (wellProject: any) => {
			return (
				<div className="project-mini-container">
					<Space direction="vertical" size={9}>
						<Space
							direction="vertical"
							size={0}
							style={{ display: "flex", flexDirection: "column" }}
						>
							<span style={{ opacity: 0.45, fontSize: "12px" }}>Well name</span>
							<span
								className={"project-title-clickable"}
								style={{
									whiteSpace: "normal",
									display: "inline",
									overflowWrap: "break-word",
								}}
								onClick={() => {
									navigate(wellProject._id, {
										replace: false,
										state: { ...wellProject },
									});
								}}
								onContextMenu={(event: any) => {
									const location = `${window.location.href}/${wellProject._id}`;
									handleContextMenu(event, { link: location });
								}}
							>
								{wellProject?.well_name}
							</span>
						</Space>
						<div className="project-status">
							{renderPurpose(wellProject, "well_purpose", "well_purpose_type")}
							{"  "}
							{renderStatus(wellProject, "well_status", "well_status_type")}
						</div>
					</Space>
				</div>
			);
		};

		const filterWellList = (wellList: any[], filters: any) => {
			// Loops through each item (well) in the wellList and returns only the wells that match all the filters.
			return wellList.filter((well) => {
				// Converts the filters object into an array of key-value pairs.
				// Example:
				// Input: { location: ["Onshore"], status: ["Active"] }
				// Output: [["location", ["Onshore"]], ["status", ["Active"]]]
				return Object.entries(filters).every(([field, selectedValues]) => {
					if (!Array.isArray(selectedValues) || selectedValues.length === 0)
						return true;
					const wellValue = well[field];
					return selectedValues.includes(wellValue);
				});
			});
		};

		const searchFilterList = (wellList: any[], searchTerm: string) => {
			if (!searchTerm) return wellList; // If no search term, return the original list
			return wellList.filter((well) =>
				well.well_name.toLowerCase().includes(searchTerm.toLowerCase())
			);
		};

		const filteredWellList =
			selectedFilters && Object.keys(selectedFilters).length > 0
				? filterWellList(wellList, selectedFilters)
				: wellList;

		const searchedWellList = searchFilterList(filteredWellList, searchValue);

		const sortWellList = (wellList: any[], descMode: boolean) => {
			return [...wellList].sort((a, b) => {
				return descMode
					? b.well_name.localeCompare(a.well_name) // Descending
					: a.well_name.localeCompare(b.well_name); // Ascending
			});
		};

		let sortedWellList = sortWellList(searchedWellList, descMode);

		let currentWellList = sortedWellList || searchedWellList || wellList;

		// Check if `currentWellList` has any items (i.e., if the list is not empty).
		if (currentWellList.length > 0) {
			return currentWellList?.map((element: any) => {
				return (
					<div
						id={`project-${element._id}`}
						className={`overview-card-list-container`}
						onClick={() => {
							selectWell(element._id, element);
						}}
					>
						{element._id === selectedWell ? (
							<>{renderDetail(element)}</>
						) : (
							<>{renderCompact(element)}</>
						)}
					</div>
				);
			});
		} else {
			return <span>No data found.</span>;
		}
	};

	if (typeof hasPermissionViewWell !== "boolean") {
		return <></>;
	}

	if (!hasPermissionViewWell && typeof hasPermissionViewWell === "boolean") {
		return (
			<NoAccess text="Oops, looks like you don't have the authorisation to view wells." />
		);
	}

	return (
		<>
			<div className="generic-container" style={{ overflow: "auto" }}>
				<div
					className="main-container"
					style={{
						minHeight: "100%",
						flex: 1,
						width: "100%",
						maxHeight: "100%",
						overflow: "hidden",
						display: "flex",
						flexDirection: "column",
					}}
				>
					{/* Container Header */}
					<div className="project-header">
						{/* Container Header - Left Container */}
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								gap: "10px",
							}}
						>
							{/* Map overview Button */}
							<div
								className={
									// If current `activePage` equals to gallery, then display the styles of `className` `project-active-button`
									activePage === "map" ? "project-active-button" : ""
								}
								style={{
									display: "flex",
									padding: "4px 15px",
									gap: "8px",
									cursor: "pointer",
								}}
								onClick={() => {
									setActivePage("map");
								}}
							>
								{GetAntIcon("aim")}
								<span>Map overview</span>
							</div>
							{/* Gallery */}
							<div
								className={
									// If current `activePage` equals to gallery, then display the styles of `className` `project-active-button`
									activePage === "gallery" ? "project-active-button" : ""
								}
								style={{
									display: "flex",
									padding: "4px 15px",
									gap: "8px",
									cursor: "pointer",
								}}
								onClick={() => {
									setActivePage("gallery");
								}}
							>
								{GetAntIcon("app")}
								<span>Gallery</span>
							</div>
						</div>
						{/* Container Header - Right Container */}
						<div
							style={{
								display: "flex",
								flex: "1",
								justifyContent: "flex-end",
								alignItems: "center",
								gap: "15px",
							}}
						>
							{/* Search Input */}
							<Input
								placeholder="Search by well name"
								className="input-clearable-panel"
								style={{ maxWidth: "265px" }}
								value={searchValue} // Bind input to the searchValue state
								onPressEnter={(e: React.KeyboardEvent<HTMLInputElement>) => {
									setSearchValue(e.currentTarget.value);
									e.currentTarget.blur();
								}}
								onChange={(e) => {
									const value = e.target.value;
									setSearchValue(value);

									// Trigger search immediately on input change
									setFilteredWellList(
										wellList.filter((well: any) =>
											well.well_name.toLowerCase().includes(value.toLowerCase())
										)
									);
								}}
								allowClear
								prefix={<SearchOutlined />}
							/>
							<div style={{ display: "flex", flexDirection: "row" }}>
								{/* Filter Button */}
								<div
									className={
										// If `filterMode` is true (filter button is active) OR at least one filter category contains selected values.
										filterMode || hasSelectedFilters()
											? "project-active-button"
											: ""
									}
									style={{ padding: "4px 10px", cursor: "pointer" }}
									onClick={() => {
										setFilterMode(!filterMode);
									}}
								>
									{GetAntIcon("filter")}
								</div>
								{/* Sort Button */}
								<div
									style={{ padding: "4px 10px", cursor: "pointer" }}
									onClick={() => {
										setDescMode(!descMode);
									}}
								>
									{descMode
										? GetAntIcon("descending")
										: GetAntIcon("ascending")}
								</div>
							</div>
						</div>
					</div>

					{activePage == "map" && (
						<div className="overview-content">
							{/* Dynamic class applied based on the `filterMode` state. */}
							<div
								className={`filter-area ${
									filterMode ? "show-filter" : "hide-filter"
								}`}
							>
								{/* If `filterMode` is true, render the filters using `filterRender`. */}
								{filterMode && filterRender}
							</div>
							<div className="overview-map" ref={tableRef}>
								<Map
									projectList={filteredWellList || wellList} // Pass the filtered well list or the full list of wells to the Map component.
									selectProject={selectWell} // Function to handle selecting a well on the map.
									selectedProject={selectedWell} // Pass the currently selected well to the Map component.
									selectedProjectInfo={selectedWellInfo} // Additional information about the selected well.
									keyType={"_id"}
								></Map>
								<div
									className="sub-container"
									style={{ maxHeight: tableDimensions.height }}
								>
									<div className="project-list-container">
										{wellList?.length > 0 ? (
											<Space className="project-list" direction="vertical">
												{listWells()}
											</Space>
										) : (
											<Space className="project-list" direction="vertical">
												<span>There are no wells available.</span>
											</Space>
										)}
									</div>
								</div>
							</div>
						</div>
					)}
					{activePage == "gallery" && (
						<div style={{ height: "100%" }}>
							<div className="overview-content" style={{ height: "100%" }}>
								<div
									className={`filter-area ${
										filterMode ? "show-filter" : "hide-filter"
									}`}
								>
									{filterMode && filterRender}
								</div>
								<div
									className="generic-content project-overview-container"
									style={{
										flex: 1,
										display: "flex",
										flexWrap: "inherit",
										flexDirection: "column",
										alignItems: "flex-start",
										padding: 0,
										gap: 0,
										overflowX: "auto",
										maxHeight: "100%",
										height: "100%",
									}}
								>
									<WellGallery
										userRole={props?.params.userRole}
										addItemPrivilege={["tenant-admin", "project-manager"]}
										allItems={wellList}
										activeItems={activeWells}
										setActiveItems={setActiveWells}
										selectedFilters={selectedFilters}
										searchValue={searchValue}
										sort={descMode}
									/>
								</div>
							</div>
						</div>
					)}
				</div>

				<WellConfigurationModal
					open={openWellModal}
					setOpen={setOpenWellModal}
					extraData={context.allData}
					user={props.params?.user}
					userRole={props.params?.userRole}
				/>
			</div>
		</>
	);
}

export default PWellOverview;
