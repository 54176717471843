import React, { useState } from "react";
import { Button, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const SearchButton = (props: any) => {
	const [searchState, setSearchState] = useState<any>(false);
	const [searchValue, setSearchValue] = useState<any>(null);

	const icon = props.icon || <SearchOutlined />;

	const handleSearch = (e: any) => {
		if (e.type == "change") {
			setSearchValue(e.target.value);
		} else {
			props.handleSearch({
				columnKey: props.columnKey,
				value: "",
			});
			setSearchValue(null);
			setSearchState(false);
		}
	};

	const handleSearchEnter = (e: any) => {
		props.handleSearch({
			columnKey: props.columnKey,
			value: e.target.value,
		});
		setSearchState(false);
	};

	return searchState ? (
		<Input
			className="input-clearable"
			onChange={handleSearch}
			onBlur={handleSearchEnter}
			onPressEnter={handleSearchEnter}
			value={searchValue}
			allowClear
			autoFocus
			prefix={<SearchOutlined />}
		/>
	) : (
		<Button
			className="search-button"
			type={props.buttonType || "default"}
			onClick={() => {
				setSearchState(true);
			}}
			style={{
				border: "none",
				boxShadow: "none",
				backgroundColor: "transparent",
			}}
			icon={icon}
		>
			{props.headerName}{" "}
			{searchValue != null && searchValue !== "" && `(${searchValue})`}
		</Button>
	);
};

export default SearchButton;
