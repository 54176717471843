export const getCrudaClass = async (lookupkey: any) => {
	try {
		// console.log("lookupkey: ", lookupkey);
		// `lookupkey` stores and passes in the exact name of the file in CRUDA directory
		const module = await import(`./CRUDA/${lookupkey}`);
		// store the object containing the module's functions and data into `ClassInstance`
		const ClassInstance =
			// `new module.default` creates an object from that class so you can use its functions and data
			new // If the module has an `export default`, it will be store in this:
			module.default();
		// console.log("ClassInstance: ", ClassInstance);
		return ClassInstance;
	} catch (error) {
		throw error;
	}
};

export const isAddButtonVisible = (lookupKey: any) => {
	const disabledKeys = ["country", "tenant", "module"];
	return !disabledKeys.includes(lookupKey);
};

export const isDeleteButtonVisible = (lookupKey: any) => {
	const disabledKeys = ["tenant"];
	return !disabledKeys.includes(lookupKey);
};

export const getAddButtonProp = (key: any) => {
	switch (key) {
		case "project_tags":
			return [
				{ key: "tag", label: "Tag" },
				{ key: "tag_group", label: "Tag group" },
			];
		case "well_purpose":
			return [
				{ key: "well_purpose", label: "Purpose" },
				{ key: "well_purpose_type", label: "Purpose type" },
			];
		case "well_status":
			return [
				{ key: "well_status", label: "Status" },
				{ key: "well_status_type", label: "Status type" },
			];
		case "well_operation":
			return [
				{ key: "existing_well", label: "Add from existing well" },
				{ key: "well", label: "Add new well" },
			];
		default:
			return null;
	}
};

export const multiCheckItems: any = (key: any) => {
	switch (key) {
		case "general_roles":
			return rolesPermissionList;
		default:
			return [];
	}
};

const rolesPermissionList = [
	{
		label: "Project visibility",
		description:
			"Control the visibility of projects within the organization's hierarchy",
		key: "project-visibility",
		keys: [
			{
				label: "View own projects",
				key: "Projects.OwnRead",
			},
			{
				label: "View projects under assignment clients",
				key: "Projects.ClientRead",
			},
			{
				label: "View ALL projects within tenant",
				key: "Projects.TenantRead",
			},
		],
	},
	{
		label: "Manage projects",
		description: "Control various aspects of project management",
		keys: [
			{
				label: "Add new projects",
				key: "ProjectManagement.Create",
			},
			{
				label: "View projects",
				key: "ProjectManagement.Read",
			},
			{ label: "Update projects", key: "ProjectManagement.Update" },
			{ label: "Delete projects", key: "ProjectManagement.Delete" },
		],
	},
];
