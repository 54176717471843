import { QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Tooltip } from "antd";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import TimeEntryModal from "../components/Modal/TimeEntryModal";
import {
	getAllProjects,
	getAllProjectsByApprover,
	getProjects,
} from "../services/api-server/projects";
import {
	getAllTimeEntriesByTenant,
	getTimeEntries,
	getTotalLoggedHoursByProject,
	updateUserWeeklyApproval,
	updateUserWeeklySubmitStatus,
} from "../services/api-server/timewriting";
import { GetAntIcon } from "../utils/ant_icons";
import Emitter from "../utils/emitter";
import { checkPrivilege, checkSuperAdminPriviledge } from "../utils/utils";
import { HomeContext } from "./Home";
import TimeBlock from "./TimeBlock";
import TimesheetManagement from "./TimesheetManagement";
import TimesheetManagementByProject from "./TimesheetManagementByProject";
import TimewritingWeeklySummary from "./TimewritingWeeklySummary";
import { getSubmmitedReviewList } from "../services/api-server/submitted_review";
import { error } from "console";

dayjs.extend(utc);
dayjs.extend(timezone);

function Timewriting(props: any) {
	const [activePage, setActivePage] = useState<any>("weekly-summary");
	// Data Fetching States
	const [projects, setProjects] = useState<any>([]);
	const [timesheetProjects, setTimesheetProjects] = useState<any>([]);
	const [tenants, setTenants] = useState<any>([]);
	const [tenantValues, setTenantValues] = useState<any>([]);
	const [userTimeEntries, setUserTimeEntries] = useState<any>([]);
	const [allTimeEntries, setAllTimeEntries] = useState<any>([]);
	const [loggedHoursByProject, setLoggedHoursByProject] = useState<any>([]);
	// Timesheet Management Selected ProjectId State
	const [projectId, setProjectId] = useState<any>(null);
	const [selectedTimesheetProject, setSelectedTimesheetProject] =
		useState<any>(null);
	// Button Reference (Add time entry button)
	const buttonRef = useRef<HTMLDivElement | null>(null);
	const [timeEntryButtonClick, setTimeEntryButtonClick] = useState(false);
	// Modal States
	const [openModal, setOpenModal] = useState(false);
	const [matchedEntry, setMatchedEntry] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	// Date Handling
	const [weekRange, setWeekRange] = useState({ start: "", end: "" });
	const [weekOffset, setWeekOffset] = useState(0);
	const [daysOfWeek, setDaysOfWeek] = useState<
		// day: "2025-01-14", string: "Monday", dayNumber: "01"
		{ day: string; name: string; dayNumber: string }[]
	>([]);
	const [selectedCol, setSelectedCol] = useState<any>(null);
	const [isEnabledLastTwoRows, setIsEnabledLastTwoRow] = useState<any>(false);
	const [modalElementType, setModalElementType] = useState<any>(null);
	const [modalPosition, setModalPosition] = useState<{
		top: number | null;
		left: number | null;
		right: number | null;
	}>({
		top: null,
		left: null,
		right: null,
	});
	// Initial Modal Values
	const [initialValues, setInitialValues] = useState<any>({
		project_id: null,
		uuid: null,
		tenant_key: null,
		date: moment(),
		time_type: null,
		duration_hours: 0,
		duration_mins: 0,
		start_time: 0,
		end_time: 0,
		description: null,
	});
	const [isApprover, setIsApprover] = useState<boolean>(false);
	const [submittedReview, setSubmittedReview] = useState<any>(null);

	// Temporary state
	const [update, setUpdate] = useState<boolean>(false);

	// Data Fetching Handling
	const context: any = useContext(HomeContext);

	useEffect(() => {
		if (context.currentTenantKey && context.tenantData) {
			// Get all tenants assigned to this current user
			let tenantValues = context.tenantData.map((tenant: any) => ({
				value: tenant.value,
				isTenantMember:
					checkSuperAdminPriviledge(props.params.userRole) ||
					tenant?.tenant_admin?.includes(props?.params?.user?.email) ||
					tenant?.tenant_members?.includes(props?.params?.user?.email),
			}));
			setTenantValues(tenantValues);

			getAllProjects(
				context.currentTenantKey, // Current tenant key
				tenantValues, // Tenants assigned to current user email
				props?.params?.user?.email // Current user email
			)
				// If the API call is successful
				.then((projectsResponse: any) => {
					// Fetch logged hours by project
					getTotalLoggedHoursByProject(
						context.currentTenantKey,
						tenantValues,
						props?.params?.user?.email
					)
						.then((loggedHoursResponse: any) => {
							// Map through projects and add `logged_hours` field
							const updatedProjects = projectsResponse.map((project: any) => {
								const matchingProject = loggedHoursResponse.find(
									(loggedProject: any) =>
										loggedProject.projectName === project.project_name
								);

								return {
									...project,
									logged_hours: matchingProject
										? Math.floor(matchingProject.totalLoggedHours) // Round down to nearest integer
										: 0, // Default to 0 if no logged hours found
								};
							});

							// Sort projects alphabetically by `project_name`
							const sortedProjects = updatedProjects.sort((a: any, b: any) => {
								if (a.project_name.toLowerCase() < b.project_name.toLowerCase())
									return -1;
								if (a.project_name.toLowerCase() > b.project_name.toLowerCase())
									return 1;
								return 0;
							});

							setProjects(sortedProjects);
							// console.log(
							// 	"Sorted Projects with `logged_hours` field: ",
							// 	sortedProjects
							// );
						})
						.catch((error: any) => {
							console.error("Error fetching logged hours by project:", error);
						});
				})
				.catch((error: any) => {
					console.error("Error fetching projects:", error);
				});

			getAllProjectsByApprover(
				context.currentTenantKey,
				tenantValues,
				props?.params?.user?.email
			).then((projectsResponse: any) => {
				setTimesheetProjects(projectsResponse);
			});
			// Get all time entries data across all tenants
			getTimeEntries({
				email: props?.params?.user?.email,
			}).then((response: any) => {
				if (Array.isArray(response)) {
					const validEntries = response.map((entry: any) => ({
						...entry,
						date: moment.utc(entry.date).local().format("YYYY-MM-DD"),
					}));
					setUserTimeEntries(validEntries);
					// console.log("Time Entries: ", validEntries);
				} else {
					console.warn("Response is not an array, setting to empty array.");
					setUserTimeEntries([]);
				}
			});

			getAllTimeEntriesByTenant()
				.then((response: any) => {
					if (Array.isArray(response)) {
						const validEntries = response.map((entry: any) => ({
							...entry,
							date: moment.utc(entry.date).local().format("YYYY-MM-DD"),
						}));
						setAllTimeEntries(validEntries);
						// console.log("Time Entries: ", validEntries);
					} else {
						console.warn("Response is not an array, setting to empty array.");
						setAllTimeEntries([]);
					}
				})
				.catch((error: any) => {
					console.error("Error fetching time entries:", error);
					setAllTimeEntries([]);
				});
		}

		setTenants(context.tenantData);
		setUpdate(false);
	}, [context.currentTenantKey, context.tenantData]);

	useEffect(() => {
		// Fetch submitted review data when user looks at other weeks.
		if (daysOfWeek.length > 0) {
			const firstDayOfWeek = new Date(
				new Date(daysOfWeek[0].day).setHours(0, 0, 0, 0)
			).toISOString();
			const lastDayOfWeek = new Date(
				new Date(daysOfWeek[daysOfWeek.length - 1].day).setHours(0, 0, 0, 0)
			).toISOString();
			getSubmmitedReviewList(
				context.userInfo.user.email,
				firstDayOfWeek,
				lastDayOfWeek
			)
				.then((response: any) => {
					if (response && response.length > 0) {
						setSubmittedReview(response);
					} else {
						setSubmittedReview(null);
					}
				})
				.catch((error) => {
					console.log("Fail to retrieve submitted review data", error);
				});
		}
	}, [daysOfWeek, update]);

	useEffect(() => {
		const getSelectedTimesheetProject = async () => {
			try {
				const project = await getAllProjects(
					context.currentTenantKey,
					tenantValues,
					props?.params?.user?.email
				);

				// console.log("projectId: ", projectId);

				const matchingProject = (project as any[]).find(
					(p: any) => p._id === projectId
				);

				if (matchingProject) {
					setSelectedTimesheetProject(matchingProject);
				}
			} catch (error) {
				console.log("Error fetching project:", error);
			}
		};
		getSelectedTimesheetProject();
	}, [projectId]);

	// Date Handling
	useEffect(() => {
		const today = new Date();
		const startOfWeek = new Date(today);
		startOfWeek.setDate(today.getDate() - today.getDay() + weekOffset * 7); // Correct start from Sunday

		const dayNames: string[] = [
			"Sunday",
			"Monday",
			"Tuesday",
			"Wednesday",
			"Thursday",
			"Friday",
			"Saturday",
		];

		const formatDate = (date: Date) =>
			date.toLocaleDateString("en-US", {
				day: "numeric",
				month: "long",
				year: "numeric",
			});

		const generatedDays = Array.from({ length: 7 }, (_, i) => {
			const currentDay = new Date(startOfWeek);
			currentDay.setDate(startOfWeek.getDate() + i); // Ensure correct date progression
			return {
				day: currentDay.toISOString().slice(0, 10),
				name: dayNames[i],
				dayNumber: String(currentDay.getDate()), // Correct date number
			};
		});

		setWeekRange({
			start: formatDate(startOfWeek),
			end: formatDate(
				new Date(startOfWeek.getTime() + 6 * 24 * 60 * 60 * 1000)
			),
		});
		setDaysOfWeek(generatedDays);
	}, [weekOffset]);

	useEffect(() => {
		const email = context.userInfo.user.email;
		if (projects && projects.length > 0) {
			const hasApprover = projects.some(
				(project: any) =>
					project.main_approver.includes(email) ||
					project.secondary_approver.includes(email)
			);
			if (hasApprover) {
				setIsApprover(true);
			} else {
				setIsApprover(false);
			}
		}
	}, [projects]);

	// Refresh Time Entries
	//get timeEntry from timeEntries if exist
	const refreshTimeEntries = () => {
		getTimeEntries({
			email: props?.params?.user?.email,
		}).then((response: any) => {
			if (Array.isArray(response)) {
				const validEntries = response.map((entry: any) => ({
					...entry,
					date: moment.utc(entry.date).local().format("YYYY-MM-DD"),
				}));
				setUserTimeEntries(validEntries);
				// console.log("Time Entries: ", validEntries);
			} else {
				console.warn("Response is not an array, setting to empty array.");
				setUserTimeEntries([]);
			}
		});

		getAllTimeEntriesByTenant()
			.then((response: any) => {
				if (Array.isArray(response)) {
					const validEntries = response.map((entry: any) => ({
						...entry,
						date: moment.utc(entry.date).local().format("YYYY-MM-DD"),
					}));
					setAllTimeEntries(validEntries);
					console.log("Time Entries: ", validEntries);
				} else {
					console.warn("Response is not an array, setting to empty array.");
					setAllTimeEntries([]);
				}
			})
			.catch((error: any) => {
				console.error("Error fetching time entries:", error);
				setAllTimeEntries([]);
			});
	};

	// delete timeEntry from timeEntries if exist
	const deleteTimeEntry = (deleteEntryKey: any) => {
		let currentTimeEntries = [...userTimeEntries];
		//find timeEntry index from timeEntries by uuid
		const timeEntryIndex = currentTimeEntries.findIndex(
			(entry: any) => entry.uuid === deleteEntryKey
		);

		//remove timeEntry from timeEntries based on index
		if (timeEntryIndex !== -1) {
			currentTimeEntries.splice(timeEntryIndex, 1);
			setUserTimeEntries(currentTimeEntries);
		}

		let currentAllTimeEntries = [...allTimeEntries];
		//find timeEntry index from timeEntries by uuid
		const allTimeEntryIndex = currentAllTimeEntries.findIndex(
			(entry: any) => entry.uuid === deleteEntryKey
		);

		//remove timeEntry from timeEntries based on index
		if (allTimeEntryIndex !== -1) {
			currentAllTimeEntries.splice(allTimeEntryIndex, 1);
			setAllTimeEntries(currentAllTimeEntries);
		}
	};

	// Handle Calculate Day/Column Duration
	const handleCalculateDayDuration = (day: any) => {
		let totalDayHours = 0;
		let totalDayMinutes = 0;

		// Iterate through each `timeEntries` array
		let timeentries =
			activePage === "timesheet-management-by-project" ||
			activePage === "timesheet-management"
				? allTimeEntries
				: userTimeEntries;
		timeentries?.forEach((entry: any) => {
			if (entry.date === day) {
				// if (entry.time_type === "duration") {
				// 	totalDayHours += entry.duration_hours;
				// 	totalDayMinutes += entry.duration_mins;
				// } else if (entry.time_type === "startend") {
				// 	const startTimeMoment = moment(entry.start_time);
				// 	const endTimeMoment = moment(entry.end_time);

				// 	if (startTimeMoment && endTimeMoment) {
				// 		const durationInMinutes = endTimeMoment.diff(
				// 			startTimeMoment,
				// 			"minutes"
				// 		);

				// 		totalDayMinutes += durationInMinutes;
				// 	}
				// }
				totalDayHours += entry.duration_hours;
				totalDayMinutes += entry.duration_mins;
			}
		});

		totalDayHours += Math.floor(totalDayMinutes / 60);
		totalDayMinutes = totalDayMinutes % 60;

		return `${totalDayHours}h ${totalDayMinutes}m`;
	};

	// Handle Calculate Week Duration
	const handleCalculateWeekDuration = () => {
		let totalWeekHours = 0;
		let totalWeekMinutes = 0;

		// Loop through each day in the week
		daysOfWeek.forEach((day) => {
			// Call handleCalculateDayDuration(day.day), which `return` the duration for a single day as a string (e.g., "8h 30m").
			// Split the string into parts `dayHours` and `dayMinutes` and parse the numbers.
			let [dayHours, dayMinutes] = handleCalculateDayDuration(day.day)
				.split(" ") // Split the string into an array, e.g., ["8h", "30m"]
				.map(
					(val, i) =>
						i % 2 === 0 // Check if the current index is even
							? parseInt(val.replace("h", "")) // Remove the "h" from hours and parse it as an integer
							: parseInt(val.replace("m", "")) // Remove the "m" from minutes and parse it as an integer
				);

			// Add the day's hours and minutes to the total week hours and minutes
			totalWeekHours += dayHours;
			totalWeekMinutes += dayMinutes;
		});

		totalWeekHours += Math.floor(totalWeekMinutes / 60); // Divide minutes by 60 to get additional hours
		totalWeekMinutes = totalWeekMinutes % 60; // Get the remaining minutes after converting to hours

		return `${totalWeekHours}h ${totalWeekMinutes}m`;
	};

	// Handle Calculate Month Duration
	const handleCalculateMonthDuration = () => {
		let totalMonthHours = 0;
		let totalMonthMinutes = 0;

		// Get the month from the start of the selected week range
		const startDate = new Date(weekRange.start);
		const currentMonth = startDate.getMonth();
		const currentYear = startDate.getFullYear();

		// Get the first and last day of the selected month
		const firstDayOfMonth = new Date(currentYear, currentMonth, 1); // First day of current month
		const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0); // Last day of current month

		// Iterate through each day of the month
		for (
			let date = new Date(firstDayOfMonth);
			date <= lastDayOfMonth;
			date.setDate(date.getDate() + 1)
		) {
			const formattedDay = date.toISOString().slice(0, 10); // Format: YYYY-MM-DD

			// Calculate the duration for the current day using handleCalculateDayDuration
			const dayDuration = handleCalculateDayDuration(formattedDay);
			if (dayDuration) {
				let [dayHours, dayMinutes] = dayDuration
					.split(" ")
					.map((val, i) =>
						i % 2 === 0
							? parseInt(val.replace("h", ""))
							: parseInt(val.replace("m", ""))
					); // Only get hours, ignore minutes
				totalMonthHours += dayHours;
				totalMonthMinutes += dayMinutes;
			}
		}

		totalMonthHours += Math.floor(totalMonthMinutes / 60);
		totalMonthMinutes = totalMonthMinutes % 60;

		return `${totalMonthHours}h ${totalMonthMinutes}m`; // Return only the total hours for the selected month
	};

	const handleShowModalCell = (
		event: React.MouseEvent<HTMLElement>, // Captures the mouse click event on a div element.
		day: string, // The day associated with the cell
		projectId: string, // Unique identifier for the project
		elementType: "div" | "button"
	) => {
		const target = event.currentTarget as HTMLElement;
		const cellRect = target.getBoundingClientRect();
		let modalPositionConfig;

		console.log(event, day, projectId, elementType);

		// Determine if the project is one of the last two projects
		const enableLastTwoRows = isLastTwoProjects(projectId);
		setIsEnabledLastTwoRow(enableLastTwoRows);

		// Modal shows on the right
		if (["Monday", "Tuesday", "Wednesday", "Thursday"].includes(day)) {
			if (elementType === "button") {
				modalPositionConfig = {
					top: enableLastTwoRows
						? cellRect.top + window.scrollY - 415 // Altered Modal
						: cellRect.top - 305, // Default Modal
					right: null,
					left: cellRect.right - 60,
				};
			} else {
				modalPositionConfig = {
					top: enableLastTwoRows
						? cellRect.top + window.scrollY - 415
						: cellRect.top - 255,
					right: null,
					left: cellRect.right - 80,
				};
			}
		} else {
			// If element type is button
			if (elementType === "button") {
				modalPositionConfig = {
					top: enableLastTwoRows
						? cellRect.top + window.scrollY - 415 // Altered Modal
						: cellRect.top - 305, // Default Modal
					right: window.innerWidth - cellRect.left + 10,
					left: null,
				};
			} else {
				modalPositionConfig = {
					top: enableLastTwoRows
						? cellRect.top + window.scrollY - 415
						: cellRect.top - 255,
					right: window.innerWidth - cellRect.left - 10,
					left: null,
				};
			}
		}

		setOpenModal(true);
		setModalPosition(modalPositionConfig);
		setModalElementType(elementType);
		setSelectedCol(day);
	};

	// Helper function to determine if the project is one of the last two
	const isLastTwoProjects = (projectId: string): boolean => {
		// Example logic to determine if a project is in the last two rows
		const lastTwoProjectIds = getLastTwoProjectIds();
		return lastTwoProjectIds.includes(projectId);
	};

	// Function to retrieve the IDs of the last two projects
	const getLastTwoProjectIds = (): string[] => {
		// Replace this with your logic for retrieving the last two project IDs
		const allProjectIds = projects.map((project: any) => project._id);
		return allProjectIds.slice(-2); // Get the last two project IDs
	};

	const handleSetTimeEntryRowCol = (
		day: string,
		projectId: any,
		userEmail?: any,
		timeEntry?: any
	) => {
		// BAD CODE
		let timeentries = timeEntry
			? timeEntry
			: activePage === "timesheet-management-by-project"
			? allTimeEntries
			: userTimeEntries;
		const entry = timeentries.find((entry: any) => {
			const entryDate = entry.date;
			if (
				activePage === "weekly-summary" ||
				activePage === "timesheet-management-by-project"
			) {
				if (userEmail) {
					console.log(entryDate === day, entry.email === userEmail);
					return (
						entry.project_id === projectId &&
						entryDate === day &&
						entry.email === userEmail
					);
				} else {
					return entry.project_id === projectId && entryDate === day;
				}
			}

			return false;
		});

		if (entry) {
			const matchedEntry = entry.date === day ? entry : null;

			if (matchedEntry) {
				if (
					activePage === "weekly-summary" ||
					activePage === "timesheet-management-by-project"
				) {
					// if (entry.time_type === "duration") {
					// 	return `${matchedEntry.duration_hours}h ${matchedEntry.duration_mins}m`;
					// } else {
					// 	//If there is start time and end time, then calculate the duration
					// 	if (entry.start_time && entry.end_time) {
					// 		const duration = dayjs(entry.end_time).diff(
					// 			dayjs(entry.start_time),
					// 			"minutes"
					// 		);

					// 		entry.duration_hours = Math.floor(duration / 60);
					// 		entry.duration_mins = duration % 60;
					// 	}
					// 	return `${entry.duration_hours}h ${entry.duration_mins}m`;
					// }
					return `${matchedEntry.duration_hours}h ${matchedEntry.duration_mins}m`;
				}
			}
		}
	};

	const handleSelectedTimeEntryData = (
		day?: string,
		project?: any,
		time: any = null
	) => {
		setTimeEntryButtonClick(false);
		// Find the time entry that matches both `day` and `projectId`
		const timeEntry = userTimeEntries.find((entry: any) => {
			const entryDate = new Date(entry.date).toISOString().slice(0, 10);

			return entry.project_id === project?._id && entryDate === day;
		});

		// console.log("Time Entry: ", timeEntry);
		if (timeEntry) {
			// Set `matchedEntry` status to true
			setMatchedEntry(true);
			// Populate `initialValues`
			setInitialValues({
				...timeEntry,
				date: moment(timeEntry.date),
				// Convert `start_time` and `end_time` to `dayjs` if `time_type` is `startend`
				...(timeEntry.time_type === "startend" && {
					start_time: dayjs(Number(timeEntry.start_time)),
					end_time: dayjs(Number(timeEntry.end_time)),
				}),
			});
			// Set `isLoading` to tsrue to prevent the modal from closing
			setIsLoading(true);

			return;
		} else {
			if (!time) {
				setInitialValues({
					project_id: project?._id,
					tenant_key: project?.tenant_key || context.currentTenantKey,
					time_type: "duration",
					date: moment(day),
				});

				setIsLoading(true); // Start loading

				return;
			} else {
				const timeSet = new Set(time);
				// Convert the Set to an array and map it to Moment objects
				const dateArray = Array.from(timeSet).map((dateString: any) =>
					moment(dateString, "H-YYYY-MM-DD")
				);

				// Find the min and max dates
				let minDate = moment.min(dateArray).clone();
				let maxDate = moment.max(dateArray).clone();

				// Check if maxDate's hour is 23 before deciding the time to add
				if (maxDate.hour() === 23) {
					// If it's 23, add 45 minutes
					maxDate = maxDate.add(45, "minutes");
				} else {
					// Otherwise, add 1 hour
					maxDate = maxDate.add(1, "hours");
				}

				setInitialValues({
					project_id: project?._id,
					tenant_key: project?.tenant_key || context.currentTenantKey,
					start_time: dayjs(minDate.valueOf()),
					end_time: dayjs(maxDate.valueOf()),
					time_type: "startend",
					date: moment(day),
				});

				setIsLoading(true); // Start loading

				return;
			}
		}
	};

	useEffect(() => {
		const data = {
			props: props,
			context: context,
			initialValues: initialValues,
			projects: projects,
		};
		console.log("Important data", data);
	}, [userTimeEntries, projects, initialValues, context]);

	const handleSubmitForReview = (
		userEmail: string,
		projectList: Array<any>,
		week: any
	) => {
		const firstDayOfWeek = new Date(
			new Date(week[0].day).setHours(0, 0, 0, 0)
		).toISOString();
		const lastDayOfWeek = new Date(
			new Date(week[week.length - 1].day).setHours(0, 0, 0, 0)
		).toISOString();
		console.log("Perry", firstDayOfWeek, lastDayOfWeek);
		Emitter.emit("loading", true);
		updateUserWeeklySubmitStatus(
			userEmail,
			projectList,
			firstDayOfWeek,
			lastDayOfWeek
		)
			.then((response: any) => {
				Emitter.emit("alert", {
					type: "success",
					message: `Review has been successfully submitted.`,
					description: "",
					top: true,
					closeable: false,
					timeout: 3000,
				});
				console.log("status", response);
				setUpdate(true);
			})
			.catch((error) => {
				console.log("Error updating user weekly approval", error);
				Emitter.emit("alert", {
					type: "alert",
					message: `Fail to submit.`,
					description: "",
					top: true,
					closeable: false,
					timeout: 3000,
				});
			})
			.finally(() => {
				Emitter.emit("loading", false);
			});
	};

	return (
		<>
			{/* Timewriting Container */}
			<div className="generic-container" style={{ gap: "20px" }}>
				<div
					className="main-container"
					style={{
						flex: 1,
						width: "100%",
						maxHeight: "100%",
						overflow: "hidden",
						display: "flex",
						flexDirection: "column",
						borderRadius: "5px",
					}}
				>
					{/* Container Panel */}
					<div className="project-header">
						{/* Tab Buttons */}
						<div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
							{/* Weekly Summary Tab Button */}
							<div
								className={
									activePage === "weekly-summary" ? "project-active-button" : ""
								}
								style={{
									display: "flex",
									padding: "4px 15px",
									gap: "8px",
									cursor: "pointer",
								}}
								onClick={() => {
									setActivePage("weekly-summary");
								}}
							>
								{GetAntIcon("project")}
								<span>Weekly summary</span>
							</div>
							{/* Time Block Tab Button */}
							{/* <div
								className={
									activePage === "time-block" ? "project-active-button" : ""
								}
								style={{
									display: "flex",
									alignItems: "center",
									padding: "4px 15px",
									gap: "8px",
									cursor: "pointer",
								}}
								onClick={() => {
									setActivePage("time-block");
								}}
							>
								{GetAntIcon("timewriting")}
								<span>Time block</span>
							</div> */}
							{/* Timesheet Management Tab Button */}
							{(checkPrivilege(context.userInfo.userRole, ["tenant-admin"]) ||
								isApprover) && (
								<div
									className={
										activePage === "timesheet-management" ||
										activePage === "timesheet-management-by-project"
											? "project-active-button"
											: ""
									}
									style={{
										display: "flex",
										padding: "4px 15px",
										gap: "8px",
										cursor: "pointer",
									}}
									onClick={() => {
										setActivePage("timesheet-management");
									}}
								>
									{GetAntIcon("clock-filled")}
									<span>Timesheet management</span>
								</div>
							)}
						</div>
						{/* Add time entry Button */}
						<div
							style={{
								display: "flex",
								flex: "1",
								justifyContent: "flex-end",
								alignItems: "center",
							}}
						>
							<div
								ref={buttonRef}
								style={{
									height: "31.74px",
									padding: "4px 15px",
									display: "flex",
									justifyContent: "end",
									alignItems: "center",
									borderRadius: "2px",
									cursor: "pointer",
								}}
								className="ant-btn-primary"
								onClick={() => {
									setOpenModal(true);
									setInitialValues({
										tenant_key: context.currentTenantKey,
										time_type: "duration",
									});
									setTimeEntryButtonClick(true);
								}}
							>
								+ Add time entry
							</div>
						</div>
					</div>
					{/* Container Content */}
					<div
						className="generic-content project-overview-container"
						style={{
							overflowY: "hidden",
							flex: 1,
							display: "flex",
							flexWrap: "inherit",
							flexDirection: "column",
							alignItems: "flex-start",
							padding: 0,
							gap: 0,
							overflowX: "auto",
							maxHeight: "100%",
						}}
					>
						{/* Table */}
						<div
							className="timewriting-table"
							style={{
								display: "flex",
								flexDirection: "column",
								width: "100%",
								height: "100%",
							}}
						>
							{/* Table container */}
							<div
								className="timewriting-table-container"
								style={{
									display: "flex",
									height: "100%",
									flexDirection: "column",
								}}
							>
								{/* Table Header First Row */}
								<div
									className="timewriting-table-header-first-row"
									style={{
										gridColumn: "1 / -1",
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
										padding: "15px 12px",
									}}
								>
									{/* First Row Left Container */}
									<div
										style={{
											display: "flex",
											alignItems: "center",
											gap: "16px",
										}}
									>
										{activePage === "timesheet-management-by-project" && (
											<>
												{/* Back Button */}
												<div
													style={{
														display: "flex",
														gap: "8px",
														cursor: "pointer",
														color: "aqua",
													}}
													onClick={() => setActivePage("timesheet-management")}
												>
													{GetAntIcon("leftarrow")}Back
												</div>
											</>
										)}
										<Button
											shape="circle"
											onClick={() => setWeekOffset((prev) => prev - 1)}
										>
											{GetAntIcon("leftarrow")}
										</Button>
										<span>
											{weekRange.start} - {weekRange.end}
										</span>
										<Button
											//disabled if the end week is more than the current day
											disabled={moment(weekRange.end).isAfter(moment())}
											shape="circle"
											onClick={() => setWeekOffset((prev) => prev + 1)}
										>
											{GetAntIcon("rightarrow")}
										</Button>
									</div>
									{/* First Row Right Container */}
									<div
										style={{
											display: "flex",
											alignItems: "center",
											gap: "15px",
										}}
									>
										{/* If `activePage` is "weekly-summary" or "time-block", show the "Submit for review" button */}
										{activePage === "weekly-summary" ||
										activePage === "time-block" ? (
											<div>
												<span style={{ fontStyle: "italic" }}>
													Weekly hours ={" "}
												</span>
												<span style={{ fontWeight: "bold" }}>
													{handleCalculateWeekDuration()}
												</span>
												<span style={{ marginLeft: "8px" }}>|</span>
												<span style={{ marginLeft: "8px" }}>
													<Tooltip
														title={
															"Monthly hours is based on month in first day of selected week."
														}
													>
														<span style={{ color: "grey" }}>
															{GetAntIcon("question3")}
														</span>
													</Tooltip>
												</span>
												<span
													style={{ marginLeft: "2px", fontStyle: "italic" }}
												>
													Monthly hours ={" "}
												</span>
												<span style={{ fontWeight: "bold" }}>
													{handleCalculateMonthDuration()}
												</span>
											</div>
										) : (
											<div>
												{activePage === "timesheet-management-by-project" &&
													selectedTimesheetProject && (
														<>
															<span style={{ fontWeight: "bold" }}>
																{selectedTimesheetProject.project_name}
															</span>
															<span> | </span>
														</>
													)}
												<span style={{ fontStyle: "italic" }}>
													Total logged hours (Week) ={" "}
												</span>
												<span style={{ fontWeight: "bold" }}>
													{handleCalculateWeekDuration()}
												</span>
												<span style={{ marginLeft: "8px" }}>|</span>
												<span style={{ marginLeft: "8px" }}>
													<Tooltip
														title={
															"Monthly hours is based on month in first day of selected week."
														}
													>
														<span style={{ color: "grey" }}>
															{GetAntIcon("question3")}
														</span>
													</Tooltip>
												</span>
												<span
													style={{ marginLeft: "2px", fontStyle: "italic" }}
												>
													Total logged hours (Month) ={" "}
												</span>
												<span style={{ fontWeight: "bold" }}>
													{handleCalculateMonthDuration()}
												</span>
											</div>
										)}
										{/* If `activePage` is "weekly-summary" or "time-block", show the "Submit for review" button */}
										{(activePage === "weekly-summary" ||
											activePage === "time-block") && (
											<span>
												<Popconfirm
													icon={
														<QuestionCircleOutlined style={{ color: "grey" }} />
													}
													title={
														<div style={{ maxWidth: "300px", padding: "10px" }}>
															<>
																Submit for review? Once submitted, this
																timesheet will be locked and cannot be edited.
															</>
														</div>
													}
													placement="bottomLeft"
													cancelButtonProps={{ ghost: true }}
													okText="Confirm"
													onConfirm={() => {
														handleSubmitForReview(
															context.userInfo.user.email,
															projects,
															daysOfWeek
														);
													}}
												>
													<Button
														className="ant-btn-secondary"
														disabled={submittedReview}
													>
														Submit for review
													</Button>
												</Popconfirm>
											</span>
										)}
									</div>
								</div>
								{activePage == "weekly-summary" && (
									<TimewritingWeeklySummary
										// Modal
										openModal={openModal}
										setOpenModal={setOpenModal}
										projects={projects}
										tenants={tenants}
										timeEntries={userTimeEntries}
										daysOfWeek={daysOfWeek}
										weekRange={weekRange}
										handleCalculateDayDuration={handleCalculateDayDuration}
										matchedEntry={matchedEntry}
										setMatchedEntry={setMatchedEntry}
										isLoading={isLoading}
										setIsLoading={setIsLoading}
										isLastTwoProjects={isLastTwoProjects}
										getLastTwoProjectIds={getLastTwoProjectIds}
										handleShowModalCell={handleShowModalCell}
										handleSetTimeEntryRowCol={handleSetTimeEntryRowCol}
										handleSelectedTimeEntryData={handleSelectedTimeEntryData}
										submittedReview={submittedReview}
									/>
								)}
								{activePage == "time-block" && (
									<TimeBlock
										// Modal
										openModal={openModal}
										setOpenModal={setOpenModal}
										projects={projects}
										tenants={tenants}
										timeEntries={userTimeEntries}
										daysOfWeek={daysOfWeek}
										weekRange={weekRange}
										handleShowModalCell={handleShowModalCell}
										handleCalculateDayDuration={handleCalculateDayDuration}
										handleCalculateWeekDuration={handleCalculateWeekDuration}
										handleCalculateMonthDuration={handleCalculateMonthDuration}
										handleSetTimeEntryRowCol={handleSetTimeEntryRowCol}
										handleSelectedTimeEntryData={handleSelectedTimeEntryData}
									/>
								)}
								{activePage == "timesheet-management" && (
									<TimesheetManagement
										projects={timesheetProjects}
										tenants={tenants}
										loggedHoursByProject={loggedHoursByProject}
										// Active page handling states
										activePage={activePage}
										setActivePage={setActivePage}
										// Project id for fetching project members based on project_id in timesheet management
										projectId={projectId}
										setProjectId={setProjectId}
									/>
								)}
								{activePage == "timesheet-management-by-project" && (
									<TimesheetManagementByProject
										openModal={openModal}
										setOpenModal={setOpenModal}
										// Data
										projects={timesheetProjects}
										tenants={tenants}
										timeEntries={allTimeEntries}
										selectedTimesheetProject={selectedTimesheetProject}
										project_id={projectId}
										// Date Handling
										handleShowModalCell={handleShowModalCell}
										handleSetTimeEntryRowCol={handleSetTimeEntryRowCol}
										handleSelectedTimeEntryData={handleSelectedTimeEntryData}
										daysOfWeek={daysOfWeek}
										weekRange={weekRange}
										submittedReview={submittedReview}
									/>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* Time Entry Modal */}
			<TimeEntryModal
				// Prop data for checking if the time entry is already exists
				timeEntries={allTimeEntries}
				// Modal Open Handling
				userData={props?.params?.user}
				openModal={openModal}
				setOpenModal={setOpenModal}
				tenants={tenants}
				projects={projects}
				timeEntryButtonClick={timeEntryButtonClick}
				setTimeEntryButtonClick={setTimeEntryButtonClick}
				// Modal Input Data Preset Handling
				matchedEntry={matchedEntry}
				setMatchedEntry={setMatchedEntry}
				initialValues={initialValues}
				setInitialValues={setInitialValues}
				refreshTimeEntries={refreshTimeEntries}
				deleteTimeEntry={deleteTimeEntry}
			/>
		</>
	);
}

export default Timewriting;
