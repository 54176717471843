import React, { useContext } from "react";
import PropTypes from "prop-types";
import elementalSvg from "../assets/image/elemental 'e'.svg";
import { Image } from "antd";
import { useLocation, useNavigate } from "react-router";

function NoAccess(props: any) {
	const navigate = useNavigate();
	const location = useLocation();
	const isHome = location.pathname.endsWith("/dashboard");
	return (
		<div className="no-access-container">
			<div className="hexlock-container">
				<div className="noaccess-icon">
					<Image
						preview={false}
						width={"185px"}
						className="home-logo"
						src={elementalSvg}
						alt="Logo"
					/>
				</div>
			</div>
			<span
				style={
					!props.ismobile
						? { fontSize: "20px", lineHeight: "30px", textAlign: "center" }
						: {
								fontSize: "20px",
								lineHeight: "30px",
								textAlign: "center",
						  }
				}
			>
				{props.text ? props.text : "Uh-oh, page not found. "}{" "}
				{props.break && <br />}
				{!isHome && (
					<span>
						Head back{" "}
						<a
							onClick={() => {
								navigate("/dashboard");
								if (props.setErrorBoundaryKey) {
									props.setErrorBoundaryKey();
								}
							}}
						>
							home.
						</a>
					</span>
				)}
			</span>
			{props.subtext ? (
				<span
					style={
						!props.ismobile
							? { fontSize: "1.4em", lineHeight: "1", textAlign: "center" }
							: {
									fontSize: "1.2em",
									lineHeight: "1",
									textAlign: "center",
							  }
					}
				>
					{props.subtext}
				</span>
			) : null}
		</div>
	);
}

NoAccess.propTypes = {
	break: PropTypes.any,
	ismobile: PropTypes.any,
	text: PropTypes.any,
	subtext: PropTypes.any,
	setErrorBoundaryKey: PropTypes.any,
};

export default NoAccess;
