import axios from "axios";
import { AuthHeader, url } from "../../utils/_exports";
import { getAllProjects } from "./projects";

const apiURL = "timewriting";

export const getAllTimeEntriesByTenant = () => {
	return new Promise((resolve, reject) => {
		const requestUrl = `${url}/${apiURL}`;
		// console.log("`getAllTimeEntriesByTenant` requestUrl: ", requestUrl);
		axios
			.get(requestUrl, {
				...AuthHeader,
			})
			.then((response: any) => {
				if (response && response.data) {
					resolve(response.data);
				} else {
					resolve(null);
				}
			})
			.catch((error: any) => {
				console.error("Error fetching time entries: ", error);
				reject(error);
			});
	});
};

export const getTimeEntries = (filter: any) => {
	return new Promise((resolve, reject) => {
		const requestUrl = `${url}/${apiURL}`;
		axios.get(requestUrl, {
			params: { filter: JSON.stringify(filter) },
			...AuthHeader,
		}).then((response: any) => {
			if (response && response.data) {
				resolve(response.data);
			} else {
				resolve(null);
			}
			console.log("response: ", response);
		}).catch((error: any) => {
			console.error("Error fetching time entries: ", error);
			reject(error);
		});
	});
};

export const createTimeEntry = (data: any) => {
	return new Promise((resolve, reject) => {
		// Sanitize the data based on time_type
		const sanitizedData = { ...data };

		if (sanitizedData.time_type === "duration") {
			sanitizedData.start_time = null;
			sanitizedData.end_time = null;
		} else if (sanitizedData.time_type === "startend") {
			sanitizedData.duration_hours = null;
			sanitizedData.duration_mins = null;
		}

		axios
			.post(`${url}/${apiURL}`, sanitizedData, AuthHeader)
			.then((_data: any) => {
				resolve(_data.data);
			})
			.catch((e: any) => {
				console.error("Error occurred:", e); // Log the error for debugging
				const errorMessage =
					e?.response?.data?.error || "An unexpected error occurred";
				reject(errorMessage);
			});
	});
};

export const updateTimeEntry = (
	/* Parameters have to be in an ascending order when passing data */
	uuid: any,
	data: any,
	prevData: any
	// formList: any
) => {
	return new Promise((resolve, reject) => {
		const requestURL = `${url}/${apiURL}`;

		// Exclude `_id` and `uuid` from the data payload
		const sanitizedData = { ...data };
		delete sanitizedData.uuid; // Remove `uuid` (preventing duplication)
		delete sanitizedData._id; // Remove `_id`(preventing duplication)

		if (sanitizedData.time_type === "duration") {
			sanitizedData.start_time = null;
			sanitizedData.end_time = null;
		} else if (sanitizedData.time_type === "startend") {
			sanitizedData.duration_hours = null;
			sanitizedData.duration_mins = null;
		}

		axios
			.put(
				`${requestURL}`,
				{
					filter: { uuid: uuid },
					data: sanitizedData,
					options: { upsert: true, new: true },
					prevData: prevData,
					// formList,
				},
				AuthHeader
			)
			.then((_data: any) => {
				resolve(_data.data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const deleteTimeEntry = (uuid: any) => {
	return new Promise((resolve, reject) => {
		axios
			.delete(`${url}/${apiURL}`, {
				params: {
					uuid: uuid,
				},
				...AuthHeader,
			})
			.then((_data) => {
				console.log("Deleteing data: ", _data);
				resolve(true);
			})
			.catch((e: any) => {
				console.error("Error deleting time entry:", e); // Log the error
				reject(e);
			});
	});
};

export const getTotalLoggedHoursByProject = (
	tenantKey: string,
	tenantList: any,
	user: any
) => {
	return new Promise((resolve, reject) => {
		// Fetch all time entries
		getAllTimeEntriesByTenant()
			.then((timeEntries: any) => {
				if (!timeEntries) {
					resolve([]);
					return;
				}

				// Aggregate hours by project
				const projectHours: { [key: string]: number } = {};

				timeEntries.forEach((entry: any) => {
					const projectId = entry.project_id;
					const durationInHours =
						entry.duration_hours + entry.duration_mins / 60;

					if (projectHours[projectId]) {
						projectHours[projectId] += durationInHours;
					} else {
						projectHours[projectId] = durationInHours;
					}
				});

				// Fetch project details
				getAllProjects(tenantKey, tenantList, user)
					.then((projects: any) => {
						const projectsWithHours = projects.map((project: any) => {
							const totalHours = projectHours[project._id] || 0;
							return {
								projectName: project.project_name,
								totalLoggedHours: parseFloat(totalHours.toFixed(2)),
							};
						});

						resolve(projectsWithHours);
					})
					.catch((error: any) => {
						console.error("Error fetching projects: ", error);
						reject(error);
					});
			})
			.catch((error: any) => {
				console.error("Error fetching time entries: ", error);
				reject(error);
			});
	});
};

export const updateUserWeeklyApproval = (project_id: string, email: string, firstDayOfWeek: string, lastDayOfWeek: string, status: string) => {
	return new Promise((resolve, reject) => {
		const requestURL = `${url}/${apiURL}`;
		const data = {
			filter: { email, project_id, date: { $gte: firstDayOfWeek, $lte: lastDayOfWeek } },
			data: {
				status: status
			},
			option: { upsert: false }
		};

		axios.put(requestURL, data, AuthHeader)
			.then((response) => {
				console.log("User weekly approval status updated successfully: ", response.data);
				resolve(response.data);
			})
			.catch((error) => {
				console.error("Error updating user weekly approval status: ", error);
				reject(error);
			});
	});
};

export const updateUserWeeklySubmitStatus = (email: string, projectList: any, firstDayOfWeek: string, lastDayOfWeek: string) => {
	return new Promise((resolve, reject) => {
		const requestURL = `${url}/${apiURL}`;

		const projectsId = projectList.map((project: any) => project._id);

		const data = {
			filter: {
				email: email,
				project_id: { $in: projectsId },
				date: { $gte: firstDayOfWeek, $lte: lastDayOfWeek }
			},
			data: {
				status: "submitted"
			},
			option: { upsert: false }
		};


		console.log("Submit for Review", email, projectList, firstDayOfWeek, lastDayOfWeek);
		axios.put(requestURL, data, AuthHeader)
			.then((response) => {
				console.log("Weekly review status updated successfully: ", response);
				const submit_data = {
					user: email,
					week: {
						start_date: firstDayOfWeek,
						end_date: lastDayOfWeek
					}
				};
				axios.post(`${url}/submitted_review`, submit_data, AuthHeader)
				resolve(response);
			})
			.catch((error) => {
				console.error("Error updating user weekly approval status: ", error);
				reject(error);
			});
	})
};
