import { useForm } from "antd/es/form/Form";
import FormButtonCancel from "../components/FormButtonCancel";
import FormButtonSave from "../components/FormButtonSave";
import { useContext, useEffect, useState } from "react";
import UploadComponent from "../components/UploadComponent";
import { ColorPicker, Divider, Flex, Form, Input, Switch } from "antd";
import { getCrudaClass } from "../utils/lookup_list";
import Emitter from "../utils/emitter";
import { HomeContext } from "./Home";
import { getInitials } from "../utils/utils";
import { useThemeContext } from "../contexts/context";
import { updateTenantItem } from "../services/api-server/tenant_management";

const TenantSettings = (props: any) => {
	const [formRef]: any = useForm();
	const [formReset, setFormReset] = useState<boolean>(true);
	const [image, setImage] = useState<any>(null);
	// const [formValues, setFormValues] = useState<any>(null);
	const [showThemeSettings, setShowThemeSettings] = useState<boolean>(false);

	const context: any = useContext(HomeContext);
	const themeContext: any = useThemeContext();

	const [currentTenant, setCurrentTenant] = useState<any>(null);

	useEffect(() => {
		Emitter.emit("loading", true);
		getCrudaClass("tenant").then((cruda: any) => {
			cruda
				.getLookupItem("tenant", context?.currentTenantKey)
				.then((_data: any) => {
					const tenantData = _data.tableData;
					if (tenantData) {
						const _currentTenant = tenantData.find(
							(tenant: any) => tenant.role_key === context.currentTenantKey
						);
						setCurrentTenant(_currentTenant);
						resetForm(_currentTenant);
						if (
							_currentTenant.default_theme !== null &&
							_currentTenant.default_theme !== undefined
						) {
							setShowThemeSettings(!_currentTenant.default_theme);
						}
					}
				})
				.catch((error: any) => {
					console.log(error);
				})
				.finally(() => {
					Emitter.emit("loading", false);
				});
		});
	}, [context.currentTenantKey, context.currentTenantData]);

	const resetForm = (values: any = null, reset: any = true) => {
		if (formRef) {
			setFormReset(true);
			reset && formRef.resetFields();
			formRef.setFieldsValue(values ? { ...values } : null);
			// To open/close theme settings based on default_theme value
			if (values.default_theme === false) {
				setShowThemeSettings(true);
			} else {
				setShowThemeSettings(false);
			}
		}
	};

	const handleFormSave = (values: any) => {
		getCrudaClass("tenant").then((cruda: any) => {
			cruda
				.updateLookupItem("tenant", null, currentTenant.uuid, null, values)
				.then((data: any) => {
					Emitter.emit("alert", {
						type: "success",
						message: "Tenant settings have been successfully updated.",
						description: "",
						top: true,
						closeable: false,
						timeout: 3000,
					});
					context.setCurrentTenantData({
						...currentTenant,
						...data,
					});
					resetForm(data);

					console.log("data", data);
					// Handle Default Theme
					themeContext.setDefaultTheme(data.default_theme); // `data.default_theme` determines whether default theme is enabled `true` or disabled `false`
					console.log("data.default_theme", data.default_theme);
					// If default theme is disabled, use `data.theme_settings` instead
					if (!data.default_theme) {
						themeContext.setThemeVariables(data.theme_settings);
					}
				})
				.catch((error: any) => {
					console.log(error);
					Emitter.emit("alert", {
						type: "error",
						message: "Failed to update. Please try again.",
						description: "",
						top: true,
						closeable: false,
						timeout: 3000,
					});
				});
		});

		// update context data and database data
	};

	const handleTenantPhotoSave = (image: any) => {
		Emitter.emit("loading", true);
		return new Promise((resolve: any, reject: any) => {
			const data = { ...currentTenant, image };
			data.logo = image;
			data.role_key = data.value;
			updateTenantItem(currentTenant.uuid, data)
				.then((data: any) => {
					Emitter.emit("tenantUpdated", null);
					Emitter.emit("alert", {
						type: "success",
						message: `Your logo has been successfully updated.`,
						description: "",
						top: true,
						closeable: false,
						timeout: 3000,
					});
					setImage(image);
					resolve(data);
				})
				.catch((error: any) => {
					Emitter.emit("alert", {
						type: "error",
						message: "Failed to update. Please try again.",
						description: "",
						top: true,
						closeable: false,
						timeout: 3000,
					});
					reject(error);
				});
		});
	};

	const handleTenantPhotoRemove = () => {
		return new Promise((resolve: any, reject: any) => {
			updateTenantItem(currentTenant.uuid, { logo: null })
				.then((data: any) => {
					Emitter.emit("tenantUpdated", null);
					Emitter.emit("alert", {
						type: "success",
						message: `Your logo has been successfully removed.`,
						description: "",
						top: true,
						closeable: false,
						timeout: 3000,
					});
					setImage(null);
					resolve(data);
				})
				.catch((error: any) => {
					Emitter.emit("alert", {
						type: "error",
						message: "Failed to update. Please try again.",
						description: "",
						top: true,
						closeable: false,
						timeout: 3000,
					});
					reject(error);
				});
		});
	};

	return (
		<>
			{context.currentTenantData && (
				<div className="crud-tab-table-modal-container tenant-settings-tab">
					<div className="main-container">
						<div className="generic-header">
							{props.headerName || "Configurations"}
						</div>
						<div className="generic-content">
							<Flex gap={20} flex={1} align="start">
								<div className="tenant-logo">
									Tenant logo
									<UploadComponent
										label={"logo"}
										prefix={""}
										initials={getInitials(currentTenant?.name, 0)}
										image={currentTenant?.logo}
										setEmptyModalOpen={() => {}}
										removeMessage={
											"Remove logo? It will be replaced with tenant's initials instead."
										}
										setImage={(image: any) => {
											setImage(image);
										}}
										handleImageSave={handleTenantPhotoSave}
										handleImageRemove={handleTenantPhotoRemove}
									></UploadComponent>
								</div>

								<div style={{ flex: "1" }}>
									<Form
										className="project-form"
										requiredMark="optional"
										layout={"vertical"}
										form={formRef}
										onChange={(event: any) => setFormReset(false)}
										onSelect={() => setFormReset(false)}
									>
										<Divider orientation="left">Tenant settings</Divider>
										<Form.Item
											label="Key"
											name="role_key"
											rules={[{ required: true, message: "" }]}
										>
											<Input disabled />
										</Form.Item>
										<Form.Item
											label="Name"
											name="name"
											rules={[{ required: true, message: "" }]}
										>
											<Input disabled />
										</Form.Item>
										<Divider orientation="left">Tenant theme</Divider>
										<Form.Item
											// className="horizontal-label"
											label={"Use default theme"}
											name="default_theme"
											rules={[{ required: true, message: "" }]}
											valuePropName="checked"
											tooltip="To set custom theme, disable Terra theme."
											layout="horizontal"
											initialValue={true}
										>
											<Switch
												onChange={(e) => {
													// formRef.setFieldValue("default_theme", e);
													setShowThemeSettings(!e);
													setFormReset(false);
													// to retain the previous theme settings when switching back to default theme
													// if (e === true) {
													// 	formRef.setFieldValue(
													// 		"theme_settings",
													// 		currentTenant.theme_settings
													// 	);
													// }
												}}
											/>
										</Form.Item>

										<div
											className="theme-pallete-container"
											style={{ display: showThemeSettings ? "block" : "none" }}
										>
											<div>Set custom theme</div>
											<Form.Item
												className="horizontal-label color-picker" // Assigns CSS classes for styling.
												label={"Primary colour"} // Sets the label displayed for the input field.
												name={["theme_settings", "primary_color"]} // Specifies the nested key path in the form's data.
												rules={[{ required: true, message: "" }]} // Ensures the field is required, but no error message is shown.
												tooltip="Sets the main theme of the app, representing primary branding and style." // Displays a tooltip explaining the field's purpose.
												getValueFromEvent={(color) => color.toHexString()} // Converts the selected color to a hex string before saving to the form.
												initialValue={"#00003C"} // Sets the default color value to a deep blue shade.
											>
												<ColorPicker
													showText
													onChange={() => {
														setFormReset(false);
													}}
												></ColorPicker>
											</Form.Item>
											<Form.Item
												className="horizontal-label color-picker"
												label={"Secondary colour"}
												name={["theme_settings", "secondary_color"]}
												rules={[{ required: true, message: "" }]}
												tooltip="Serves as the complementary accent to the primary colour, used to highlight additional elements."
												getValueFromEvent={(color) => color.toHexString()}
												initialValue={"#00003C"}
											>
												<ColorPicker
													showText
													onChange={() => {
														setFormReset(false);
													}}
												></ColorPicker>
											</Form.Item>
											<Form.Item
												className="horizontal-label color-picker"
												label={"Default button"}
												name={["theme_settings", "default_btn_color"]}
												rules={[{ required: true, message: "" }]}
												tooltip="Sets the base colour for standard buttons."
												getValueFromEvent={(color) => color.toHexString()}
												initialValue={"#00BEBE"}
											>
												<ColorPicker
													showText
													onChange={() => {
														setFormReset(false);
													}}
												></ColorPicker>
											</Form.Item>
											<Form.Item
												className="horizontal-label color-picker"
												label={"Danger button"}
												name={["theme_settings", "danger_btn_color"]}
												rules={[{ required: true, message: "" }]}
												tooltip="Sets the colour for actions that have significant consequences, such as deletions or irreversible changes."
												getValueFromEvent={(color) => color.toHexString()}
												initialValue={"#FF4D4F"}
											>
												<ColorPicker
													showText
													onChange={() => {
														setFormReset(false);
													}}
												></ColorPicker>
											</Form.Item>
										</div>
									</Form>
								</div>
							</Flex>
						</div>
						<div className="generic-footer">
							<FormButtonCancel
								form={formRef}
								handleFormCancel={() => resetForm(currentTenant)}
								formReset={formReset}
							/>
							<FormButtonSave
								form={formRef}
								handleFormSave={handleFormSave}
								formReset={formReset}
							/>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default TenantSettings;
