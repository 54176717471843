import axios from "axios";
import { AuthHeader, url } from "../../utils/_exports";

const apiURL = "submitted_review";

export const getSubmmitedReviewList = (email: string, start_date: string, end_date: string) => {
    const filter = {
        "user": email,
        "week.start_date": start_date,
        "week.end_date": end_date
    };
    return new Promise((resolve, reject) => {
        const requestUrl = `${url}/${apiURL}`;
        axios
            .get(requestUrl, {
                params: { filter: JSON.stringify(filter) },
                ...AuthHeader,
            })
            .then((response: any) => {
                if (response && response.data) {
                    resolve(response.data);
                } else {
                    resolve(null);
                }
            })
            .catch((error: any) => {
                console.error("Error fetching time entries: ", error);
                reject(error);
            });
    });
};