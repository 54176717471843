/**
 * One stop all AntD Icons
 */

import * as antdIcon from "@ant-design/icons";
import { ReactComponent as WellIcon } from "../assets/image/WellIcon.svg";

var fileIconJs = require("file-icons-js");

const WellSvg = () => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 458 780"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_15265_169377)">
			<path
				d="M203.531 1C204 281.354 204 561.708 204 842.531C182.979 843 161.958 843 140.469 843C141.802 840.367 143.356 837.516 145.448 835.138C154.404 824.956 163.684 815.053 172.473 804.732C175.073 801.68 177.153 798.239 177.143 793.432C176.952 695.944 177 598.454 177 500.965C177 470.31 177.124 439.655 176.97 409.001C176.756 366.253 176.286 323.506 176.021 280.757C175.898 260.953 176 241.147 176 221.342C176 148.207 176 75.0721 176 1.46866C185.021 1 194.042 1 203.531 1Z"
				fill="currentColor"
			/>
			<path
				d="M256.469 843C256 562.646 256 282.292 256 1.46866C265.021 1 274.042 1 283.531 1C284 65.8052 284 130.61 284 195.416C284 218.049 284 240.682 284 263.314C284 305.27 284.125 347.227 283.959 389.182C283.836 420.088 283.083 450.993 283.046 481.899C282.924 583.346 283.166 684.794 282.741 786.24C282.699 796.291 286.067 803.258 292.5 810.216C302.053 820.548 310.868 831.561 319.999 842.642C298.979 843 277.958 843 256.469 843Z"
				fill="currentColor"
			/>
			<path
				d="M139.531 1C140 167.796 140 334.592 140 501.694C118.631 501.694 97.4415 501.694 75.2041 501.694C78.9941 497.031 82.2241 492.864 85.6564 488.871C94.3365 478.774 103.182 468.817 111.748 458.624C112.823 457.346 112.949 454.99 112.951 453.132C113.013 405.305 113 357.477 113 309.65C113 225.16 113.057 140.67 112.954 56.1808C112.932 38.0993 112.333 20.0185 112 1.46866C121.021 1 130.042 1 139.531 1Z"
				fill="currentColor"
			/>
			<path
				d="M347.531 1C348 9.49896 348.017 17.998 347.997 26.4969C347.668 168.23 347.346 309.962 346.933 451.695C346.922 455.551 347.725 458.41 350.569 461.311C354.639 465.461 357.751 470.53 361.547 474.971C367.022 481.378 372.762 487.558 378.352 493.869C380.379 496.157 382.298 498.541 384.855 501.582C362.776 501.582 341.634 501.582 320 501.582C320 335.111 320 168.524 320 1.46866C329.021 1 338.042 1 347.531 1Z"
				fill="currentColor"
			/>
			<path
				d="M1 264.333C1.3329 263.994 1.82132 264.131 1.97624 263.962C7.991 257.402 14.0685 250.896 19.9322 244.203C24.1189 239.423 27.7645 234.155 32.1055 229.531C36.76 224.572 39.2306 219.533 39.1965 212.242C38.8684 142.141 39 72.0391 39 1.46866C47.6876 1 56.3751 1 65.5313 1C66 89.1762 66 177.352 66 266C44.4336 266 23.1854 266 1.46866 266C1 265.556 1 265.111 1 264.333Z"
				fill="currentColor"
			/>
			<path
				d="M420.531 1C421 71.8211 421.089 142.642 420.837 213.463C420.816 219.399 422.582 223.805 426.359 227.869C430.766 232.611 435.328 237.221 439.525 242.142C445.481 249.124 451.159 256.343 456.987 263.435C457.252 263.757 457.837 263.816 458.636 263.999C459 264.444 459 264.889 459 265.667C437.544 266 416.087 266 394 266C394 177.872 394 89.9047 394 1.46866C402.688 1 411.375 1 420.531 1Z"
				fill="currentColor"
			/>
		</g>
		<defs>
			<clipPath id="clip0_15265_169377">
				<rect width="458" height="780" fill="currentColor" />
			</clipPath>
		</defs>
	</svg>
);

export const ant_icons = [
	"rocket",
	"dashboard",
	"map",
	"piechart",
	"area",
	"bar",
	"dot",
	"line",
	"radar",
	"heat",
	"fall",
	"rise",
	"stock",
	"boxplot",
	"fund",
	"slider",
	"setting",
	"bulb",
	"team",
	"user",
	"infocircle",
	"info",
	"barcode",
	"menu",
	"robot",
	"pushpin",
	"heart",
	"star",
	"gift",
	"comment",
	"notification",
	"clock",
	"edit",
	"image",
	"file",
	"experiment",
	"search",
	"mail",
	"aim",
	"alert",
	"tool",
	"contacts",
	"question",
	"close",
	"delete",
	"menufold",
	"menuunfold",
	"ExclamationCircleOutlined",
	"timewriting",
	"apartment",
	"profile",
];

export const GetFileType = (filename: string) => {
	return fileIconJs.getClassWithColor(filename);
};

export const GetAntIcon = (type: string) => {
	if (type === undefined || type === null) return <></>;
	switch ((type as string).toLowerCase()) {
		case "map":
			return <antdIcon.EnvironmentOutlined className="antd-icon" />;
		case "history":
			return <antdIcon.HistoryOutlined className="antd-icon" />;
		case "number":
			return <antdIcon.NumberOutlined className="antd-icon" />;
		case "plus":
			return <antdIcon.PlusOutlined className="antd-icon" />;
		case "minus":
			return <antdIcon.MinusOutlined className="antd-icon" />;
		case "delete":
			return <antdIcon.DeleteOutlined className="antd-icon" />;
		case "pushpin":
			return <antdIcon.PushpinOutlined className="antd-icon" />;
		case "contacts":
		case "contact":
			return <antdIcon.ContactsOutlined className="antd-icon" />;
		case "refresh":
			return <antdIcon.SyncOutlined className="antd-icon" />;
		case "reload":
			return <antdIcon.ReloadOutlined className="antd-icon" />;
		case "tool":
			return <antdIcon.ToolOutlined className="antd-icon" />;
		case "bank":
			return <antdIcon.BankOutlined className="antd-icon" />;
		case "search":
			return <antdIcon.SearchOutlined className="antd-icon" />;
		case "gift":
			return <antdIcon.GiftOutlined className="antd-icon" />;
		case "pound":
			return <antdIcon.PoundOutlined className="antd-icon" />;
		case "dollar":
			return <antdIcon.DollarOutlined className="antd-icon" />;
		case "euro":
			return <antdIcon.EuroCircleOutlined className="antd-icon" />;
		case "aim":
			return <antdIcon.AimOutlined className="antd-icon" />;
		case "alert":
			return <antdIcon.AlertOutlined className="antd-icon" />;
		case "like":
			return <antdIcon.LikeOutlined className="antd-icon" />;
		case "dislike":
			return <antdIcon.DislikeOutlined className="antd-icon" />;
		case "scan":
			return <antdIcon.ScanOutlined className="antd-icon" />;
		case "shop":
			return <antdIcon.ShopOutlined className="antd-icon" />;
		case "mail":
			return <antdIcon.MailOutlined className="antd-icon" />;
		case "lock":
			return <antdIcon.LockOutlined className="antd-icon" />;
		case "unlock":
			return <antdIcon.UnlockOutlined className="antd-icon" />;
		case "experiment":
			return <antdIcon.ExperimentOutlined className="antd-icon" />;
		case "trophy":
			return <antdIcon.TrophyOutlined className="antd-icon" />;
		case "happy":
			return <antdIcon.SmileOutlined className="antd-icon" />;
		case "meh":
			return <antdIcon.MehOutlined className="antd-icon" />;
		case "sad":
			return <antdIcon.FrownOutlined className="antd-icon" />;
		case "img":
		case "image":
			return <antdIcon.FileImageOutlined className="antd-icon" />;
		case "edit":
			return <antdIcon.EditOutlined className="antd-icon" />;
		case "cloud":
			return <antdIcon.CloudOutlined className="antd-icon" />;
		case "cloud-download":
			return <antdIcon.CloudDownloadOutlined className="antd-icon" />;
		case "download":
			return <antdIcon.DownloadOutlined className="antd-icon" />;
		case "cloud-upload":
			return <antdIcon.CloudUploadOutlined className="antd-icon" />;
		case "upload":
			return <antdIcon.UploadOutlined className="antd-icon" />;
		case "sync":
			return <antdIcon.CloudSyncOutlined className="antd-icon" />;
		case "comment":
			return <antdIcon.CommentOutlined className="antd-icon" />;
		case "clock":
			return <antdIcon.ClockCircleOutlined className="antd-icon" />;
		case "clock-filled":
			return <antdIcon.ClockCircleFilled className="antd-icon" />;
		case "issue":
			return <antdIcon.IssuesCloseOutlined className="antd-icon" />;
		case "barcode":
			return <antdIcon.BarcodeOutlined className="antd-icon" />;
		case "cart":
			return <antdIcon.ShoppingCartOutlined className="antd-icon" />;
		case "star":
			return <antdIcon.StarOutlined className="antd-icon" />;
		case "shopping":
			return <antdIcon.ShoppingOutlined className="antd-icon" />;
		case "tag":
			return <antdIcon.TagOutlined className="antd-icon" />;
		case "robot":
			return <antdIcon.RobotOutlined className="antd-icon" />;
		case "leftarrow":
			return <antdIcon.LeftOutlined className="antd-icon" />;
		case "rightarrow":
			return <antdIcon.RightOutlined className="antd-icon" />;
		case "downarrow":
			return <antdIcon.DownOutlined className="antd-icon" />;
		case "piechart":
			return <antdIcon.PieChartOutlined className="antd-icon" />;
		case "setting":
		case "settings":
			return <antdIcon.SettingOutlined className="antd-icon" />;
		case "dashboard":
			return <antdIcon.DashboardOutlined className="antd-icon" />;
		case "dash":
			return <antdIcon.DashOutlined className="antd-icon" />;
		case "small-dash":
			return <antdIcon.SmallDashOutlined className="antd-icon" />;
		case "logout":
			return <antdIcon.LogoutOutlined className="antd-icon" />;
		case "bulb":
			return <antdIcon.BulbOutlined className="antd-icon" />;
		case "heart":
			return <antdIcon.HeartOutlined className="antd-icon" />;
		case "team":
			return <antdIcon.TeamOutlined className="antd-icon" />;
		case "user":
			return <antdIcon.UserOutlined className="antd-icon" />;
		case "file":
			return <antdIcon.FileOutlined className="antd-icon" />;

		case "file2":
			return <antdIcon.FileTextOutlined className="antd-icon" />;
		case "rocket":
			return <antdIcon.RocketOutlined className="antd-icon" />;
		case "infocircle":
			return <antdIcon.InfoCircleOutlined className="antd-icon" />;
		case "info":
			return <antdIcon.InfoOutlined className="antd-icon" />;
		case "menu":
			return <antdIcon.MenuOutlined className="antd-icon" />;
		case "notification":
			return <antdIcon.NotificationOutlined className="antd-icon" />;
		case "coffee":
			return <antdIcon.CoffeeOutlined className="antd-icon" />;
		case "question":
			return <antdIcon.QuestionOutlined className="antd-icon" />;
		case "question3":
			return <antdIcon.QuestionCircleOutlined className="antd-icon" />;
		case "question2":
			return (
				<antdIcon.QuestionCircleOutlined
					style={{ color: "red" }}
					className="antd-icon"
				/>
			);
		case "folder":
			return (
				<antdIcon.FolderTwoTone
					style={{ fontSize: "20px" }}
					twoToneColor={"#F8D775"}
					className="antd-icon"
				/>
			);
		case "folder-outline":
			return <antdIcon.FolderOutlined className="antd-icon" />;
		case "borderless":
			return <antdIcon.BorderlessTableOutlined className="antd-icon" />;
		case "back":
			return (
				<antdIcon.ArrowLeftOutlined
					style={{ fontSize: "15px" }}
					className="antd-icon"
				/>
			);
		case "deploymentunit":
			return <antdIcon.DeploymentUnitOutlined className="antd-icon" />;
		case "compass":
			return <antdIcon.CompassOutlined className="antd-icon" />;
		case "layout":
			return <antdIcon.LayoutOutlined className="antd-icon" />;
		case "close":
			return <antdIcon.CloseOutlined className="antd-icon" />;
		case "pdf":
			return <antdIcon.FilePdfOutlined className="antd-icon" />;
		case "arrowexpand":
			return <antdIcon.ArrowsAltOutlined className="antd-icon" />;
		case "expand":
			return <antdIcon.ExpandOutlined className="antd-icon" />;
		case "compress":
			return <antdIcon.CompressOutlined className="antd-icon" />;
		case "collapse":
			return <antdIcon.ShrinkOutlined className="antd-icon" />;
		case "comingsoon":
			return <antdIcon.FieldTimeOutlined className="antd-icon" />;
		case "ellipsis":
			return <antdIcon.EllipsisOutlined className="antd-icon" />;
		case "area":
			return <antdIcon.AreaChartOutlined className="antd-icon" />;
		case "bar":
			return <antdIcon.BarChartOutlined className="antd-icon" />;
		case "dot":
			return <antdIcon.DotChartOutlined className="antd-icon" />;
		case "line":
			return <antdIcon.LineChartOutlined className="antd-icon" />;
		case "radar":
			return <antdIcon.RadarChartOutlined className="antd-icon" />;
		case "heat":
			return <antdIcon.HeatMapOutlined className="antd-icon" />;
		case "fall":
			return <antdIcon.FallOutlined className="antd-icon" />;
		case "rise":
			return <antdIcon.RiseOutlined className="antd-icon" />;
		case "stock":
			return <antdIcon.StockOutlined className="antd-icon" />;
		case "boxplot":
			return <antdIcon.BoxPlotOutlined className="antd-icon" />;
		case "fund":
			return <antdIcon.FundOutlined className="antd-icon" />;
		case "slider":
			return <antdIcon.SlidersOutlined className="antd-icon" />;
		case "menufold":
			return <antdIcon.MenuFoldOutlined className="antd-icon" />;
		case "menuunfold":
			return <antdIcon.MenuUnfoldOutlined className="antd-icon" />;
		case "check":
			return <antdIcon.CheckOutlined />;
		case "checkcircle":
			return <antdIcon.CheckCircleOutlined className="antd-icon" />;
		case "group":
			return <antdIcon.GroupOutlined className="antd-icon" />;
		case "ungroup":
			return <antdIcon.UngroupOutlined className="antd-icon" />;
		case "exclamation":
			return <antdIcon.ExclamationCircleOutlined className="antd-icon" />;
		case "up":
			return <antdIcon.UpOutlined className="antd-icon" />;
		case "bell":
			return <antdIcon.BellOutlined className="antd-icon" />;
		case "project":
			return <antdIcon.ProjectOutlined className="antd-icon" />;
		case "global":
			return <antdIcon.GlobalOutlined className="antd-icon" />;
		case "linkedin":
			return <antdIcon.LinkedinOutlined className="antd-icon" />;
		case "export":
			return <antdIcon.ExportOutlined className="antd-icon" />;
		case "app":
			return <antdIcon.AppstoreOutlined className="antd-icon" />;
		case "ascending":
			return <antdIcon.SortAscendingOutlined className="antd-icon" />;
		case "descending":
			return <antdIcon.SortDescendingOutlined className="antd-icon" />;
		case "filter":
			return <antdIcon.FilterOutlined className="antd-icon" />;
		case "thunderbolt":
			return <antdIcon.ThunderboltOutlined className="antd-icon" />;
		case "form":
			return <antdIcon.FormOutlined className="antd-icon" />;
		case "phone":
			return <antdIcon.PhoneOutlined className="antd-icon" />;
		case "play":
			return <antdIcon.PlayCircleOutlined className="antd-icon" />;
		case "security":
			return <antdIcon.SecurityScanOutlined className="antd-icon" />;
		case "reorder":
			return <antdIcon.PartitionOutlined className="antd-icon" />;
		case "link":
			return <antdIcon.LinkOutlined className="antd-icon" />;
		case "double-arrow-up":
			return (
				<antdIcon.DoubleRightOutlined
					style={{ transform: "rotate(-90deg)" }}
					className="antd-icon"
				/>
			);
		case "double-arrow-down":
			return (
				<antdIcon.DoubleRightOutlined
					style={{ transform: "rotate(90deg)" }}
					className="antd-icon"
				/>
			);

		case "product":
			return <antdIcon.ProductOutlined className="antd-icon" />;
		case "square-check":
			return (
				<svg
					width="27"
					height="28"
					viewBox="0 0 27 28"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<rect x="6.5" y="7" width="14" height="14" stroke="currentColor" />
					<g filter="url(#filter0_d_882_102135)">
						<rect x="11" y="11.5" width="5" height="5" fill="currentColor" />
					</g>
					<defs>
						<filter
							id="filter0_d_882_102135"
							x="0"
							y="0.5"
							width="27"
							height="27"
							filterUnits="userSpaceOnUse"
							colorInterpolationFilters="sRGB"
						>
							<feFlood floodOpacity="0" result="BackgroundImageFix" />
							<feColorMatrix
								in="SourceAlpha"
								type="matrix"
								values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
								result="hardAlpha"
							/>
							<feMorphology
								radius="4"
								operator="dilate"
								in="SourceAlpha"
								result="effect1_dropShadow_882_102135"
							/>
							<feOffset />
							<feGaussianBlur stdDeviation="3.5" />
							<feComposite in2="hardAlpha" operator="out" />
							<feColorMatrix
								type="matrix"
								values="0 0 0 0 0.486275 0 0 0 0 0.72549 0 0 0 0 0.741176 0 0 0 0.44 0"
							/>
							<feBlend
								mode="normal"
								in2="BackgroundImageFix"
								result="effect1_dropShadow_882_102135"
							/>
							<feBlend
								mode="normal"
								in="SourceGraphic"
								in2="effect1_dropShadow_882_102135"
								result="shape"
							/>
						</filter>
					</defs>
				</svg>
			);
		case "well-icon":
			return <WellIcon width={16} height={16} />;
		case "timewriting":
			return (
				<svg
					width="16"
					height="16"
					viewBox="0 0 16 16"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M6.40039 1.06641H9.24484M7.82261 9.59974L9.95595 7.46641"
						stroke="currentColor"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M7.82268 15.2918C10.9646 15.2918 13.5116 12.7448 13.5116 9.60295C13.5116 6.46106 10.9646 3.91406 7.82268 3.91406C4.68079 3.91406 2.13379 6.46106 2.13379 9.60295C2.13379 12.7448 4.68079 15.2918 7.82268 15.2918Z"
						stroke="currentColor"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>
			);
		case "appstore":
			return <antdIcon.AppstoreOutlined className="antd-icon" />;
		case "apartment":
			return <antdIcon.ApartmentOutlined className="antd-icon" />;
		case "profile":
			return <antdIcon.ProfileOutlined className="antd-icon" />;
		default:
			return <antdIcon.HolderOutlined className="antd-icon" />;
	}
};
