import { QuestionCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Divider, Image, Input, InputRef, Popconfirm } from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import {
	getTimeEntries,
	updateUserWeeklyApproval,
} from "../services/api-server/timewriting";
import { getAllUsers } from "../services/api-server/user";
import { GetAntIcon } from "../utils/ant_icons";
import Emitter from "../utils/emitter";
import { getInitials } from "../utils/utils";
import { HomeContext } from "./Home";
import moment from "moment";

function TimesheetManagementByProject(props: any) {
	const context: any = useContext(HomeContext);

	// Filter States
	const inputRef = useRef<InputRef | null>(null); // Use InputRef here
	// Search project by name
	const [searchHovered, setSearchHovered] = useState<any>(false);
	const [searchShow, setSearchShow] = useState<any>(false);
	const [searchValue, setSearchValue] = useState<string>("");
	// Cursor
	const [hoveredCell, setHoveredCell] = useState<string | null>(null);
	// General User State
	const [users, setUsers] = useState<any>(null);
	const [reviewedUsers, setReviewedUsers] = useState<any>([]);
	const [timeEntryData, setTimeEntryData] = useState<any>(null);

	useEffect(() => {
		const filter = {
			project_id: props.project_id,
			status: { $in: ["submitted", "approved", "rejected"] },
			date: {
				$gte: new Date(props.weekRange.start).toISOString(),
				$lte: new Date(props.weekRange.end).toISOString(),
			},
		};

		Emitter.emit("loading", true);
		getTimeEntries(filter)
			.then((data: any) => {
				const validEntries = data.map((entry: any) => ({
					...entry,
					date: moment.utc(entry.date).local().format("YYYY-MM-DD"),
				}));
				console.log("validEntries: ", validEntries);
				setTimeEntryData(validEntries);
			})
			.catch((error: any) => {
				console.log("Fail to get time entry data!", error);
			})
			.finally(() => {
				Emitter.emit("loading", false);
			});
		// getSubmmitedReviewList(
		// 		context.userInfo.user.email,
		// 		firstDayOfWeek,
		// 		lastDayOfWeek
		// 	)
	}, [props.weekRange]);

	// Used to fetch all users' data (profile pictures and names)
	useEffect(() => {
		const fetchUsers = async () => {
			try {
				const response = await getAllUsers();
				if (response) {
					setUsers(response);
				} else {
					setUsers([]);
				}
				console.log("Users", response);
			} catch (error: any) {
				console.log("Error fetching users:", error);
			}
		};

		fetchUsers();
	}, [props.selectedTimesheetProject]);

	// Irham
	const handleApproval = (
		project_id: string,
		email: string,
		week: any,
		action: string
	) => {
		const firstDayOfWeek = new Date(week[0].day).toISOString();
		const lastDayOfWeek = new Date(week[week.length - 1].day).toISOString();
		console.log("Review Approval", action);
		Emitter.emit("loading", true);
		updateUserWeeklyApproval(
			project_id,
			email,
			firstDayOfWeek,
			lastDayOfWeek,
			action
		)
			.then((response: any) => {
				Emitter.emit("alert", {
					type: "success",
					message: `Review has been successfully ${action}.`,
					description: "",
					top: true,
					closeable: false,
					timeout: 3000,
				});
				setReviewedUsers((prev: any) => Array.from(new Set([...prev, email])));
			})
			.catch((error) => {
				console.log("Error updating user weekly approval", error);
				Emitter.emit("alert", {
					type: "alert",
					message: `Fail to submit.`,
					description: "",
					top: true,
					closeable: false,
					timeout: 3000,
				});
			})
			.finally(() => {
				Emitter.emit("loading", false);
			});
	};

	const timeSheetManagementByProjectHeader = () => {
		console.log(
			"props.selectedTimesheetProject: ",
			props.selectedTimesheetProject
		);

		// console.log("projectId: ", props.selectedTimesheetProject._id);

		const listMembers = () => {
			const filterMembers = (memberList: string[], searchTerm: string) => {
				if (!searchTerm) return memberList;
				return memberList.filter((member: string) =>
					member.toLowerCase().includes(searchTerm.toLowerCase())
				);
			};

			// Get matching users based on emails
			const matchedUsers = users?.filter(
				(user: any) =>
					props.selectedTimesheetProject?.general_members.includes(
						user.email
					) || props.selectedTimesheetProject?.project_manager === user.email
			);

			console.log("matchedUsers: ", matchedUsers);

			// Filtered member list based on search
			const filteredMemberList = filterMembers(
				matchedUsers?.map((user: any) => user.email) || [],
				searchValue
			);

			console.log("filteredMemberList: ", filteredMemberList);

			const currentMemberList = filteredMemberList?.length
				? filteredMemberList
				: matchedUsers?.map((user: any) => user.email);

			console.log("currentMemberList: ", currentMemberList);

			return (
				<div>
					{currentMemberList && currentMemberList.length > 0 ? (
						currentMemberList.map((email: string, memberIndex: number) => {
							const userData = matchedUsers?.find(
								(user: any) => user.email === email
							);

							return (
								<div
									key={memberIndex}
									style={{
										display: "grid",
										gridTemplateColumns: "220px repeat(8, minmax(105px, 1fr))",
										height: "96px",
									}}
								>
									{/* Member Name Cell */}
									<div
										className="timewriting-table-main-col"
										style={{
											backgroundColor: "#131344",
											padding: "12px 16px",
											border: "1px solid grey",
											display: "flex",
											alignItems: "center",
											justifyContent: "start",
											color: "#fff",
										}}
									>
										<div
											style={{
												height: "100%",
												display: "flex",
												alignItems: "center",
												gap: "12px",
											}}
										>
											{/* User image */}
											<div style={{ cursor: "pointer" }}>
												{userData?.profile_picture ? (
													<Image
														style={{
															border: "1px solid #d9d9d9",
															borderRadius: "180px",
															minWidth: "32px",
															maxWidth: "32px",
														}}
														width={32}
														height={32}
														preview={false}
														src={userData.profile_picture}
													/>
												) : (
													<div
														className="profile-picture-initials"
														style={{
															width: "32px",
															minWidth: "32px",
															maxWidth: "32px",
															height: "32px",
														}}
													>
														<span
															className="profile-initials"
															style={{ fontSize: "12px" }}
														>
															{getInitials(userData?.name || userData?.name, 0)}
														</span>
													</div>
												)}
											</div>
											{/* Member's name and date */}
											<div
												style={{
													display: "flex",
													flexDirection: "column",
													gap: "8px",
												}}
											>
												<div>{userData?.name || email}</div>
												<div style={{ color: "grey" }}>7 Dec 2024</div>
											</div>
										</div>
									</div>
									{/* Time Entry Columns */}
									{props.daysOfWeek.map((day: any) => (
										<div
											key={`${memberIndex}-${day.day}`}
											className="timewriting-table-col"
											style={{
												padding: "12px 16px",
												backgroundColor:
													hoveredCell === `${memberIndex}-${day.day}`
														? "#FFFFFF20"
														: "#FFFFFF40",
												border: "1px solid grey",
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
												transition: "background-color 0.3s ease",
												cursor: "pointer",
											}}
											onMouseEnter={() =>
												handleMouseEnterCell(memberIndex, day.day)
											}
											onMouseLeave={handleMouseLeaveCell}
										/>
									))}
									<div
										style={{
											padding: "12px 16px",
											backgroundColor: "#FFFFFF40",
											border: "1px solid grey",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											gap: "8px",
											transition: "background-color 0.3s ease",
											cursor: "pointer",
										}}
									>
										<div style={{ color: "green", cursor: "pointer" }}>
											Approve
										</div>
										|
										<div style={{ color: "red", cursor: "pointer" }}>
											Reject
										</div>
									</div>
								</div>
							);
						})
					) : (
						<div
							style={{
								height: "96px",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								backgroundColor: "#FFFFFF40",
								border: "1px solid grey",
							}}
						>
							No members available for this project.
						</div>
					)}
				</div>
			);
		};

		// Cell Mouse Hover Handling
		const handleMouseEnterCell = (row: number, day: string) => {
			setHoveredCell(`${row}-${day}`);
		};

		const handleMouseLeaveCell = () => {
			setHoveredCell(null);
		};

		return (
			<>
				{/* Header Second Row */}
				<div
					style={{
						display: "grid",
						width: "100%",
						gridTemplateColumns: "220px repeat(8, 1fr)",
						backgroundColor: "#0C0C2A",
					}}
				>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
						}}
					>
						<div
							style={{
								display: "flex",
								flexGrow: 1,
								color: searchHovered ? "aqua" : "inherit",
								cursor: searchHovered ? "pointer" : "default",
							}}
							onMouseEnter={() => setSearchHovered(true)}
							onMouseLeave={() => setSearchHovered(false)}
							onClick={() => {
								setSearchShow((prev: any) => !prev);
								setTimeout(() => {
									if (!searchShow && inputRef.current) {
										inputRef.current.focus();
									}
								}, 0);
							}}
						>
							{/* First Column Left Container */}
							<div>
								{searchShow ? (
									<>
										{/* Search members input */}
										<Input
											ref={inputRef} // Attach the ref to the Input component
											placeholder="Search by operation name"
											className="input-clearable-panel"
											style={{
												maxWidth: "265px",
											}}
											onPressEnter={(
												e: React.KeyboardEvent<HTMLInputElement>
											) => {
												setSearchValue(e.currentTarget.value);
												e.currentTarget.blur();
											}}
											onChange={(e) => {
												const value = e.target.value;
												setSearchValue(value);
											}}
											allowClear
											prefix={<SearchOutlined />}
										/>
									</>
								) : (
									<>
										{/* List of team members */}
										<div
											style={{
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
												width: "100%", // Ensures full width
											}}
										>
											<div style={{ padding: "4px 10px" }}>
												{GetAntIcon("search")}
											</div>
											<div>List of team members</div>
										</div>
									</>
								)}
							</div>
						</div>
					</div>
					{/* Days of week mapping */}
					{props.daysOfWeek.map((day: any) => (
						<div style={{ display: "flex", alignItems: "center" }}>
							<Divider type="vertical" style={{ margin: 0 }} />
							<div
								style={{
									width: "100%",
									padding: "12px",
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
								}}
							>
								<div
									key={day.day}
									style={{
										display: "flex",
										flexDirection: "column",
									}}
								>
									{/* Day number (01) */}
									<span style={{ fontSize: "20px" }}>{day.dayNumber}</span>
									{/* Day name (Monday) */}
									<span>{day.name}</span>
								</div>
								{/* Time entries total duration columns calculation */}
								<div style={{ color: "aqua" }}>
									{/* {props.handleCalculateDayDuration(day.day)} */}
								</div>
							</div>
						</div>
					))}
					<div
						style={{
							padding: "12px",
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						Reviews {GetAntIcon("filter")}
					</div>
				</div>
			</>
		);
	};

	const handleTimeEntryRender = (timeEntry: any, userEmail: any, day: any) => {
		console.log(day, userEmail, timeEntry);
		console.log("timeEntry: ", timeEntry);
		return (
			props.handleSetTimeEntryRowCol(
				day.day,
				props.selectedTimesheetProject._id,
				userEmail,
				timeEntry
			) && (
				<Button
					className="ant-btn-primary"
					onClick={(e) => {
						e.stopPropagation();
						props.handleShowModalCell(
							e,
							day.name,
							props.selectedTimesheetProject._id,
							"button"
						);
						props.handleSelectedTimeEntryData(
							day.day,
							props.selectedTimesheetProject
						);
					}}
				>
					{props.handleSetTimeEntryRowCol(
						day.day,
						props.selectedTimesheetProject._id,
						userEmail,
						timeEntry
					)}
				</Button>
			)
		);
	};

	const listMembers = () => {
		const filterMembers = (memberList: string[], searchTerm: string) => {
			if (!searchTerm) return memberList;
			return memberList.filter((member: string) =>
				member.toLowerCase().includes(searchTerm.toLowerCase())
			);
		};

		const projectManager = props.selectedTimesheetProject?.project_manager;
		//get general members from general_members field
		const generalMembers =
			props.selectedTimesheetProject?.general_members || [];
		//get members from roles_assignment array of objects
		const rolesAssignment =
			props.selectedTimesheetProject?.roles_assignments || [];
		//get members from roles_assignment array of objects
		const members = rolesAssignment.map((role: any) => [...role?.appointee]);

		//combine project manager and general members
		const totalMembers = [...generalMembers, projectManager, ...members];
		//get unique members
		const uniqueMembers = Array.from(new Set(totalMembers));

		// Get matching users based on emails
		const matchedUsers = users?.filter((user: any) =>
			uniqueMembers.includes(user.email)
		);

		console.log("matchedUsers: ", matchedUsers);

		// Filtered member list based on search
		const filteredMemberList = filterMembers(
			matchedUsers?.map((user: any) => user.email) || [],
			searchValue
		);

		console.log("filteredMemberList: ", filteredMemberList);

		const currentMemberList = filteredMemberList?.length
			? filteredMemberList
			: matchedUsers?.map((user: any) => user.email);

		console.log("currentMemberList: ", currentMemberList);

		return (
			<div>
				{currentMemberList && currentMemberList.length > 0 ? (
					currentMemberList.map((email: string, memberIndex: number) => {
						const userData = matchedUsers?.find(
							(user: any) => user.email === email
						);

						return (
							<div
								key={memberIndex}
								style={{
									display: "grid",
									gridTemplateColumns: "220px repeat(8, minmax(105px, 1fr))",
									height: "96px",
									backgroundColor: reviewedUsers.includes(email)
										? "rgba(41,41,41,45)"
										: "rgba(0,0,0,0)",
								}}
							>
								{/* Member Name Cell */}
								<div
									className="timewriting-table-main-col"
									style={{
										backgroundColor: "#131344",
										padding: "12px 16px",
										border: "1px solid grey",
										display: "flex",
										alignItems: "center",
										justifyContent: "start",
										color: "#fff",
									}}
								>
									<div
										style={{
											height: "100%",
											display: "flex",
											alignItems: "center",
											gap: "12px",
										}}
									>
										{/* User image */}
										<div style={{ cursor: "pointer" }}>
											{userData?.profile_picture ? (
												<Image
													style={{
														border: "1px solid #d9d9d9",
														borderRadius: "180px",
														minWidth: "32px",
														maxWidth: "32px",
													}}
													width={32}
													height={32}
													preview={false}
													src={userData.profile_picture}
												/>
											) : (
												<div
													className="profile-picture-initials"
													style={{
														width: "32px",
														minWidth: "32px",
														maxWidth: "32px",
														height: "32px",
													}}
												>
													<span
														className="profile-initials"
														style={{ fontSize: "12px" }}
													>
														{getInitials(userData?.name || userData?.name, 0)}
													</span>
												</div>
											)}
										</div>
										{/* Member's name and date */}
										<div
											style={{
												display: "flex",
												flexDirection: "column",
												gap: "8px",
											}}
										>
											<div>{userData?.name || email}</div>
											<div style={{ color: "grey" }}>7 Dec 2024</div>
										</div>
									</div>
								</div>
								{/* Time Entry Columns */}
								{props.daysOfWeek.map((day: any) => (
									<div
										key={`${memberIndex}-${day.day}`}
										className="timewriting-table-col"
										style={{
											padding: "12px 16px",
											// backgroundColor: "#FFFFFF40",
											border: "1px solid grey",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											transition: "background-color 0.3s ease",
											cursor: "pointer",
										}}
									>
										{handleTimeEntryRender(timeEntryData, email, day)}
									</div>
								))}
								<div
									style={{
										padding: "12px 16px",
										backgroundColor: "#FFFFFF40",
										border: "1px solid grey",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										gap: "8px",
										transition: "background-color 0.3s ease",
										cursor: "pointer",
									}}
								>
									<Popconfirm
										icon={<QuestionCircleOutlined style={{ color: "grey" }} />}
										title={
											<div style={{ maxWidth: "300px", padding: "10px" }}>
												<>
													Approve this timesheet? Once approved it will be
													finalised and cannot be modified.
												</>
											</div>
										}
										placement="bottomLeft"
										cancelButtonProps={{ ghost: true }}
										okText="Confirm"
										onConfirm={() =>
											handleApproval(
												props.selectedTimesheetProject._id,
												email,
												props.daysOfWeek,
												"approved"
											)
										}
									>
										<Button
											style={{ color: "green" }}
											type="link"
											onClick={(e) => e.preventDefault()}
										>
											Approve
										</Button>
									</Popconfirm>
									<Divider type="vertical" />
									<Popconfirm
										icon={<QuestionCircleOutlined style={{ color: "grey" }} />}
										title={
											<div style={{ maxWidth: "300px", padding: "10px" }}>
												<>Reject this timesheet?</>
											</div>
										}
										placement="bottomLeft"
										cancelButtonProps={{ ghost: true }}
										okText="Confirm"
										onConfirm={() =>
											handleApproval(
												props.selectedTimesheetProject._id,
												email,
												props.daysOfWeek,
												"rejected"
											)
										}
									>
										<Button
											style={{ color: "red" }}
											type="link"
											onClick={(e) => e.preventDefault()}
										>
											Reject
										</Button>
									</Popconfirm>
								</div>
							</div>
						);
					})
				) : (
					<div
						style={{
							height: "96px",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							backgroundColor: "#FFFFFF40",
							border: "1px solid grey",
						}}
					>
						No members available for this project.
					</div>
				)}
			</div>
		);
	};

	return (
		<div
			style={{
				height: "100%",
				width: "100%",
				overflow: "auto",
				backgroundColor: "#414166",
			}}
		>
			{timeSheetManagementByProjectHeader()}
			{listMembers()}
		</div>
	);
}

export default TimesheetManagementByProject;
