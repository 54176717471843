import axios from "axios";
import { url, AuthHeader } from "../../utils/_exports";

export const getUser = (email: any) => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${url}/users`, {
				...AuthHeader,
				params: {
					filter: {
						email: email,
					},
				},
			})
			.then((_data: any) => {
				if (_data?.data && _data.data.length) {
					resolve(_data.data);
				} else {
					resolve(null);
				}
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const getAllUsers = () => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${url}/users`, {
				...AuthHeader,
			})
			.then((_data: any) => {
				if (_data?.data && _data.data.length) {
					resolve(_data.data);
				} else {
					resolve(null);
				}
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const addUser = (userData: any) => {
	return new Promise((resolve, reject) => {
		axios
			.post(`${url}/users`, userData, AuthHeader)
			.then((_data: any) => {
				console.log(_data);
				resolve(_data.data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const updateUser = (userData: any) => {
	return new Promise((resolve, reject) => {
		axios
			.put(
				`${url}/users`,
				{
					filter: { email: userData.email },
					data: userData,
					options: { upsert: true, new: true },
				},
				AuthHeader
			)
			.then((_data: any) => {
				console.log(_data);
				resolve(_data.data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};
