import React, { useContext, useEffect, useRef, useState } from "react";
import { GetAntIcon } from "../utils/ant_icons";
import { Input, InputRef, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { HomeContext } from "./Home";
function TimesheetManagement(props: any) {
	// Search Input References
	const projectInputRef = useRef<InputRef | null>(null);
	const clientInputRef = useRef<InputRef | null>(null);
	// Search Project Name States
	const [searchProjectShow, setSearchProjectShow] = useState<any>(false);
	const [searchProjectValue, setSearchProjectValue] = useState<string>("");
	// Search Project Client States
	const [searchClientShow, setSearchClientShow] = useState<any>(false);
	const [searchClientValue, setSearchClientValue] = useState<string>("");
	// Sort Switch State
	const [sortSwitch, setSortSwitch] = useState<
		"members" | "loggedHours" | string
	>("");
	const [sortMembers, setSortMembers] = useState<boolean>(false);
	const [sortLoggedHours, setSortLoggedHours] = useState<boolean>(false);
	const [sortSubmittedTimesheet, setSortSubmittedTimesheet] =
		useState<boolean>(false);
	const [sortPendingReview, setSortPendingReview] = useState<boolean>(false);
	const [sortApproved, setSortApproved] = useState<boolean>(false);
	const [sortRejected, setSortRejected] = useState<boolean>(false);
	// Search Handling
	const searchProjectList = (
		projectList: any[], // `props.projects`
		projectSearch: string, // `searchProjectValue` (value of the search project input)
		clientSearch: string // `searchClientValue` (value of the search client input)
	) => {
		return projectList.filter(
			(project) =>
				project.project_name
					.toLowerCase()
					.includes(projectSearch.toLowerCase()) &&
				project.client_name.toLowerCase().includes(clientSearch.toLowerCase())
		);
	};

	const searchedProjectList = searchProjectList(
		props.projects,
		searchProjectValue,
		searchClientValue
	);

	// Sort Total Members Handling
	const sortTotalMembers = (projectList: any[], sortMembers: boolean) => {
		return [...projectList].sort((a, b) => {
			const totalA =
				(a.general_members?.length || 0) + (a.project_manager ? 1 : 0);
			const totalB =
				(b.general_members?.length || 0) + (b.project_manager ? 1 : 0);

			return sortMembers ? totalA - totalB : totalB - totalA;
		});
	};

	// Sort Total Logged Hours Handling
	const sortTotalLoggedHours = (
		projectList: any[],
		sortLoggedHours: boolean
	) => {
		return [...projectList].sort((a, b) => {
			const hoursA = a.logged_hours || 0;
			const hoursB = b.logged_hours || 0;

			return sortLoggedHours ? hoursA - hoursB : hoursB - hoursA;
		});
	};
	const sortTotalPendingReview = (
		projectList: any[],
		sortSubmittedTimesheet: boolean
	) => {
		return [...projectList].sort((a, b) => {
			const hoursA = a.logged_hours || 0;
			const hoursB = b.logged_hours || 0;

			return sortLoggedHours ? hoursA - hoursB : hoursB - hoursA;
		});
	};
	const sortTotalApproved = (
		projectList: any[],
		sortSubmittedTimesheet: boolean
	) => {
		return [...projectList].sort((a, b) => {
			const hoursA = a.logged_hours || 0;
			const hoursB = b.logged_hours || 0;

			return sortLoggedHours ? hoursA - hoursB : hoursB - hoursA;
		});
	};
	const sortTotalRejected = (
		projectList: any[],
		sortSubmittedTimesheet: boolean
	) => {
		return [...projectList].sort((a, b) => {
			const hoursA = a.logged_hours || 0;
			const hoursB = b.logged_hours || 0;

			return sortLoggedHours ? hoursA - hoursB : hoursB - hoursA;
		});
	};

	let currentProjectList =
		sortSwitch === "members"
			? sortTotalMembers(searchedProjectList, sortMembers)
			: sortSwitch === "loggedHours"
			? sortTotalLoggedHours(searchedProjectList, sortLoggedHours)
			: searchedProjectList; // Default to the original sorted project list

	const columns = [
		{
			title: (
				<div
					onClick={() => {
						setSearchProjectShow((prev: any) => !prev);
						if (searchProjectShow && searchProjectValue) {
							setSearchProjectValue(""); // Clear input if it has data before closing
						} else {
							setTimeout(() => {
								if (!searchProjectShow && projectInputRef.current) {
									projectInputRef.current.focus();
								}
							}, 0);
						}
					}}
				>
					{searchProjectShow ? (
						<>
							{/* Search projects input */}
							<Input
								ref={projectInputRef} // Attach the ref to the Input component
								placeholder="Search project by name"
								className="input-clearable-panel"
								style={{
									maxWidth: "265px",
								}}
								onPressEnter={(e) => {
									setSearchProjectValue(e.currentTarget.value);
								}}
								onChange={(e) => {
									const value = e.target.value;
									setSearchProjectValue(value);
								}}
								allowClear
								prefix={<SearchOutlined />}
							/>
						</>
					) : (
						<>
							{/* Projects */}
							<div
								style={{
									display: "flex",
									justifyContent: "start",
									alignItems: "center",
								}}
							>
								<div style={{ padding: "4px 10px" }}>
									{GetAntIcon("search")}
								</div>
								<div>Projects</div>
							</div>
						</>
					)}
				</div>
			),
			dataIndex: "project_name",
			key: "project_name",
			render: (text: string, record: any) => {
				// Find the tenant for the current project
				const tenant = props.tenants.find(
					(tenant: any) => tenant.value === record.tenant_key
				);

				return (
					<div
						onClick={() => {
							props.setActivePage("timesheet-management-by-project");
							props.setProjectId(record._id);
						}}
					>
						<span>{text}</span>
						<span
							style={{
								display: "flex",
								gap: "8px",
								fontSize: "12px",
								color: "grey",
							}}
						>
							<span
								style={{ display: "flex", gap: "4px", alignItems: "center" }}
							>
								{GetAntIcon("apartment")}
								{tenant.name}
							</span>
							<span
								style={{ display: "flex", gap: "4px", alignItems: "center" }}
							>
								{GetAntIcon("profile")}
								{record.client_name}
							</span>
						</span>
					</div>
				);
			},
		},
		{
			title: (
				<div
					onClick={() => {
						setSearchClientShow((prev: any) => !prev);
						if (searchClientShow && searchClientValue) {
							setSearchClientValue("");
						} else {
							setTimeout(() => {
								if (!searchClientShow && clientInputRef.current) {
									clientInputRef.current.focus();
								}
							}, 0);
						}
					}}
				>
					{searchClientShow ? (
						<>
							<Input
								ref={clientInputRef} // Attach the ref to the Input component
								placeholder="Search client by name"
								className="input-clearable-panel"
								style={{
									maxWidth: "265px",
								}}
								onPressEnter={(e) => {
									setSearchClientValue(e.currentTarget.value);
								}}
								onChange={(e) => {
									const value = e.target.value;
									setSearchClientValue(value);
								}}
								allowClear
								prefix={<SearchOutlined />}
							/>
						</>
					) : (
						<>
							<span style={{ paddingRight: "8px" }}>
								{GetAntIcon("search")}
							</span>
							Owner/Operator
						</>
					)}
				</div>
			),
			dataIndex: "client_name",
			key: "client_name",
		},
		{
			title: (
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<span>No. of members</span>
					<span
						onClick={() => {
							setSortMembers(!sortMembers);
							setSortSwitch("members");
						}}
					>
						{!sortMembers ? GetAntIcon("ascending") : GetAntIcon("descending")}
					</span>
				</div>
			),
			// dataIndex: ["general_members", "project_manager"],
			key: "total_members",
			render: (_: any, record: any) => {
				console.log(record);
				//get project manager from project_manager field
				const projectManager = record.project_manager;
				//get general members from general_members field
				const generalMembers = record.general_members;
				//get members from roles_assignment array of objects
				const rolesAssignment = record.roles_assignments || [];
				//get members from roles_assignment array of objects
				const members = rolesAssignment.map((role: any) => [...role.appointee]);

				//combine project manager and general members
				const totalMembers = [...generalMembers, projectManager, members];

				//other way to get unique members
				const uniqueMembers = Array.from(new Set(totalMembers));

				//count the number of general members
				// const generalMembersCount = generalMembers
				// 	? generalMembers.length
				// 	: 0;
				// const projectManagerCount = record.project_manager ? 1 : 0;
				// return generalMembersCount + projectManagerCount;

				return uniqueMembers.length;
			},
		},
		{
			title: (
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<span>Total logged hours</span>
					<span
						onClick={() => {
							setSortLoggedHours(!sortLoggedHours);
							setSortSwitch("loggedHours");
						}}
					>
						{!sortLoggedHours
							? GetAntIcon("ascending")
							: GetAntIcon("descending")}
					</span>
				</div>
			),
			dataIndex: "logged_hours",
			key: "logged_hours",
		},
		{
			title: (
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<span>Submitted timesheet</span>
					<span>{GetAntIcon("ascending")}</span>
				</div>
			),
			dataIndex: "submitted_timesheet",
			key: "submitted_timesheet",
		},
		{
			title: (
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<span>Pending review</span>
					<span>{GetAntIcon("ascending")}</span>
				</div>
			),
			dataIndex: "pending_review",
			key: "pending_review",
		},
		{
			title: (
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<span>Approved</span>
					<span>{GetAntIcon("ascending")}</span>
				</div>
			),
			dataIndex: "approved",
			key: "approved",
		},
		{
			title: (
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<span>Rejected</span>
					<span>{GetAntIcon("ascending")}</span>
				</div>
			),
			dataIndex: "rejected",
			key: "rejected",
		},
	];

	return (
		<>
			<Table
				rootClassName="timesheet-management-table"
				columns={columns}
				dataSource={currentProjectList}
				pagination={false}
				bordered
			/>
		</>
	);
}

export default TimesheetManagement;
