import { useMemo, useRef, useState } from "react";
import { GetAntIcon } from "../utils/ant_icons";
import { Divider, Button, InputRef, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import CheckboxDropdown from "../components/CheckboxDropdown";

function TimewritingWeeklySummary(props: any) {
	// Search Input Reference
	const inputRef = useRef<InputRef | null>(null);
	// Search project by name
	const [searchHovered, setSearchHovered] = useState<any>(false);
	const [searchShow, setSearchShow] = useState<any>(false);
	const [searchValue, setSearchValue] = useState<string>("");
	// Fitler project by tenant
	const [selectedFilters, setSelectedFilters] = useState<any>({
		tenant: [],
	});
	// Sort project by alphabet
	const [descMode, setDescMode] = useState<boolean>(false);
	// Cursor
	const [hoveredCell, setHoveredCell] = useState<string | null>(null);

	console.log("Weekly summary props: ", props);

	// Filter by Handling
	// useMemo: Ensures the filterRender component is only re-created when filterMode or selectedFilters changes, improving performance by avoiding unnecessary re-renders.
	const filterRender = useMemo(() => {
		// This function is called when a filter's selection changes.
		const handleMenuClick =
			// field: Represents the filter category (e.g., "location").
			// selectedValues: The selected values for the filter category.
			(field: any, selectedValues: any[]) => {
				setSelectedFilters({ ...selectedFilters, [field]: selectedValues });
				// console.log("handleMenuClick - selectedFilters: ", selectedFilters);

				// Stores the updated filters in localStorage so the selections persist across page reloads.
				localStorage.setItem(
					"selectedFilters",
					JSON.stringify({ ...selectedFilters, [field]: selectedValues })
				);
			};

		return (
			<div>
				{/* Filter by Tenant Dropdown */}
				<CheckboxDropdown
					options={props.tenants.map((tenant: any) => ({
						label: tenant.name,
						value: tenant.role_key,
					}))}
					onChange={(selectedValues) =>
						handleMenuClick("tenant_key", selectedValues)
					}
					onClear={""}
					defaultValue={selectedFilters["tenant_key"]}
					Key="tenant_key"
					label={GetAntIcon("filter")}
					page={"timewriting"}
				/>
			</div>
		);
	}, [props.projects, selectedFilters]);

	const listProjects = () => {
		const filterProjectList = (
			// To store `projects`
			projectList: any[],
			// filters is an object where each key represents a filter field (tenant)
			filters: any
		) => {
			return projectList.filter((project) => {
				// console.log("Processing Project: ", project);
				// Converts the `filters` object into an array of key-value pairs
				return Object.entries(filters).every(([field, selectedValues]) => {
					// console.log("Field:", field, "Selected Values:", selectedValues);

					// If the array is empty (no selectedValue) then skip this section
					if (!Array.isArray(selectedValues) || selectedValues.length === 0)
						return true;

					// Normalize the field name (e.g., trim and lowercase)
					const normalizedField = field.trim().toLowerCase();
					// console.log("Normalized Field:", normalizedField);

					// Normalize project keys to lowercase
					const normalizedProjectKeys = Object.keys(project).map((key) =>
						key.toLowerCase()
					);
					// console.log("Normalized Project Keys:", normalizedProjectKeys);

					// Check if the normalizedField is present in the project keys
					if (!normalizedProjectKeys.includes(normalizedField)) {
						console.warn(`Field '${normalizedField}' not found in project.`);
						return false;
					}

					// Get the value of the field from the project object
					const projectValue = project[normalizedField];
					// console.log(
					// 	`Field Value from Project: ${normalizedField} = ${projectValue}`
					// );

					return selectedValues.includes(projectValue);
				});
			});
		};

		// console.log("Selected Filters:", selectedFilters);
		// Declare a variable to hold the filtered list of projects.
		const filteredProjectList =
			selectedFilters && Object.keys(selectedFilters).length > 0
				? (() => {
						// Log that filtering is being applied
						// console.log("Filtering projects with filters:", selectedFilters);

						// Log the filtered list (assuming filterProjectList is well-defined)
						const filtered = filterProjectList(props.projects, selectedFilters);
						// console.log("Filtered Projects List:", filtered);
						return filtered;
				  })()
				: (() => {
						// Log that no filters are applied and the full list is used
						// console.log("No filters applied, returning full projects list.");
						return props.projects;
				  })();

		// Search Handling
		const searchFilterList = (projectList: any[], searchTerm: string) => {
			if (!searchTerm) return projectList;
			return projectList.filter((project) =>
				project.project_name.toLowerCase().includes(searchTerm.toLowerCase())
			);
		};

		const searchedProjectList = searchFilterList(
			filteredProjectList,
			searchValue
		);

		// Sort Handling
		const sortProjectList = (projectList: any[], descMode: boolean) => {
			return [...projectList].sort((a, b) => {
				const nameA = a.project_name || ""; // Default to empty string if undefined
				const nameB = b.project_name || "";

				return descMode
					? nameB.localeCompare(nameA) // Descending
					: nameA.localeCompare(nameB); // Ascending
			});
		};

		let sortedProjectList = sortProjectList(searchedProjectList, descMode);

		let currentProjectList =
			sortedProjectList || searchedProjectList || props.projects;

		if (currentProjectList.length > 0) {
			return (
				<div>
					{currentProjectList.map((project: any) => {
						const tenant = props.tenants.find(
							(tenant: any) => tenant.value === project.tenant_key
						);

						return (
							<div
								style={{
									display: "grid",
									gridTemplateColumns: "300px repeat(7, minmax(120px, 1fr))", // First column is 300px, repeat for another 7 columns that have a min width of 120px
									height: "96px",
									backgroundColor: props.submittedReview
										? "rgba(41, 41, 41, 0.45)"
										: "rgba(41, 41, 41,0)",
									// "submitted" == "submitted" ? "black" : "rgba(0, 0, 0, 0)",
								}}
							>
								{/* Project Column */}
								<div
									key={project._id}
									className="timewriting-table-first-col"
									style={{
										backgroundColor: "#131344",
										padding: "12px 16px",
										border: "1px solid grey",
										display: "flex",
										flexDirection: "column",
										gap: "8px",
									}}
								>
									<span>{project.project_name}</span>
									<div
										style={{
											display: "flex",
											gap: "8px",
											fontSize: "12px",
											color: "grey",
										}}
									>
										<span style={{ display: "flex", gap: "4px" }}>
											{GetAntIcon("apartment")} {tenant.name}
										</span>
										<span style={{ display: "flex", gap: "4px" }}>
											{GetAntIcon("profile")} {project.client_name}
										</span>
									</div>
								</div>

								{/* Time Entry Duration Columns */}
								{props.daysOfWeek.map((day: any) => (
									<div
										key={`${project._id}-${day.day}`}
										className="timewriting-table-col"
										style={{
											padding: "12px 16px",
											backgroundColor:
												hoveredCell === `${project._id}-${day.day}`
													? "#FFFFFF20"
													: "rgba(0, 0, 0, 0)",
											border: "1px solid grey",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											transition: "background-color 0.3s ease",
											cursor: props.submittedReview ? "default" : "pointer",
											pointerEvents: props.submittedReview ? "none" : "auto",
										}}
										onMouseEnter={() =>
											handleMouseEnterCell(project._id, day.day)
										}
										onMouseLeave={handleMouseLeaveCell}
										onClick={(e) => {
											// Run `handleShowModalCell`
											props.handleShowModalCell(
												e,
												day.name,
												project._id,
												"div"
											);
											// Run `handleSelectedTimeEntryData` (preset `tenant_key`, `project_id`, and `date`)
											props.handleSelectedTimeEntryData(day.day, project);
										}}
									>
										{/* If handleSetTimeEntryRowCol has been run */}
										{props.handleSetTimeEntryRowCol(day.day, project._id) && (
											<Button
												className="ant-btn-primary"
												onClick={(e) => {
													e.stopPropagation();
													props.handleShowModalCell(
														e,
														day.name,
														project._id,
														"button"
													);
													props.handleSelectedTimeEntryData(day.day, project);
												}}
												style={
													props.submittedReview && {
														backgroundColor: "rgba(67, 67, 67, 1)",
														color: "rgba(0, 190, 190, 1)",
													}
												}
											>
												{props.handleSetTimeEntryRowCol(day.day, project._id)}
											</Button>
										)}
									</div>
								))}
							</div>
						);
					})}
				</div>
			);
		}
	};

	// Cell Mouse Hover Handling
	const handleMouseEnterCell = (row: number, day: string) => {
		setHoveredCell(`${row}-${day}`);
	};

	const handleMouseLeaveCell = () => {
		setHoveredCell(null);
	};

	return (
		<>
			<div
				style={{
					height: "100%",
					width: "100%",
					overflow: "auto",
					// backgroundColor: "#414166",
				}}
				className="weekly-summary-table-container"
			>
				<div
					style={{
						display: "grid",
						gridTemplateColumns: "300px repeat(7, 1fr)", // 1 fixed + 7 equal columns
					}}
					className="weekly-summary-header"
				>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
						}}
					>
						<div
							style={{
								display: "flex",
								flexGrow: 1,
								color: searchHovered ? "aqua" : "inherit",
								cursor: searchHovered ? "pointer" : "default",
							}}
							onMouseEnter={() => setSearchHovered(true)}
							onMouseLeave={() => setSearchHovered(false)}
							onClick={() => {
								setSearchShow((prev: any) => !prev);
								if (searchShow && searchValue) {
									setSearchValue(""); // Clear input if it has data before closing
								} else {
									setTimeout(() => {
										if (!searchShow && inputRef.current) {
											inputRef.current.focus();
										}
									}, 0);
								}
							}}
						>
							{/* First Column Left Container */}
							<div>
								{searchShow ? (
									<>
										{/* Search projects input */}
										<Input
											ref={inputRef}
											placeholder="Search project"
											className="input-clearable-panel"
											style={{
												maxWidth: "265px",
											}}
											onPressEnter={(
												e: React.KeyboardEvent<HTMLInputElement>
											) => {
												setSearchValue(e.currentTarget.value);
											}}
											onChange={(e) => {
												const value = e.target.value;
												setSearchValue(value);
											}}
											allowClear
											prefix={<SearchOutlined />}
										/>
									</>
								) : (
									<>
										{/* List of projects */}
										<div
											style={{
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
											}}
										>
											<div style={{ padding: "4px 10px" }}>
												{GetAntIcon("search")}
											</div>
											<div>List of projects</div>
										</div>
									</>
								)}
							</div>
						</div>
						{/* Right Container */}
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "end",
								width: "fit-content",
							}}
						>
							{/* Filter Button */}
							<span
								style={{
									padding: "4px 10px",
									cursor: "pointer",
								}}
							>
								{filterRender}
							</span>
							{/* Sorting Button */}
							<span
								style={{
									padding: "4px 10px",
									cursor: "pointer",
								}}
								onClick={() => {
									setDescMode(!descMode);
								}}
							>
								{descMode ? GetAntIcon("descending") : GetAntIcon("ascending")}
							</span>
						</div>
					</div>
					{/* Days of week mapping */}
					{props.daysOfWeek.map((day: any) => (
						<div style={{ display: "flex", alignItems: "center" }}>
							<Divider type="vertical" style={{ margin: 0 }} />
							<div
								style={{
									width: "100%",
									padding: "12px",
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
								}}
							>
								<div
									key={day.day}
									style={{
										display: "flex",
										flexDirection: "column",
									}}
								>
									{/* Day number (01) */}
									<span style={{ fontSize: "20px" }}>{day.dayNumber}</span>
									{/* Day name (Monday) */}
									<span>{day.name}</span>
								</div>
								{/* Time entries total duration columns calculation */}
								<div style={{ color: "aqua" }}>
									{props.handleCalculateDayDuration(day.day)}
								</div>
							</div>
						</div>
					))}
				</div>
				{listProjects()}
			</div>
		</>
	);
}

export default TimewritingWeeklySummary;
